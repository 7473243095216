import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimelineMax, TweenMax, Power2, Power4 } from 'gsap';

import Text from 'components/ui/Text';
import { isMinScreenSize } from 'utils';

import styles from './SwipeIndicator.scss';

const Arrow = ({ className }) => (
    <svg className={className} viewBox="0 0 9 18">
        <path d="M0 .515L8.485 9 0 17.485z" fill="#000" fillRule="evenodd" />
    </svg>
);

Arrow.propTypes = {
    className: PropTypes.string,
};

const SwipeIndicator = ({ isVisible }) => {
    let animation;

    useEffect(() => {
        const speed = 0.75;
        const ease = Power4.easeInOut;

        if (isMinScreenSize('desktop') || !isVisible) return;

        animation = new TimelineMax({ repeat: -1 });

        animation.add([
            TweenMax.set(`.${styles.root}`, {
                width: 160,
                x: '-50%',
                ease,
                z: 0.1,
            }),
            TweenMax.set(`.${styles.arrowLeft}`, { x: 0, ease, z: 0.1 }),
            TweenMax.set(`.${styles.label}`, { x: 0, ease, z: 0.1 }),
        ]);

        animation.add([
            TweenMax.to(`.${styles.root}`, speed, {
                width: '+=20',
                x: '-=20',
                ease,
            }),
            TweenMax.to(`.${styles.arrowLeft}`, speed, { x: '-=10', ease }),
            TweenMax.to(`.${styles.label}`, speed, { x: '-=20', ease }),
        ]);

        animation.add([
            TweenMax.to(`.${styles.root}`, speed, {
                width: '-=20',
                x: '-50%',
                ease,
            }),
            TweenMax.to(`.${styles.arrowLeft}`, speed, { x: 0, ease }),
            TweenMax.to(`.${styles.label}`, speed, { x: 0, ease }),
        ]);

        animation.add([
            TweenMax.to(`.${styles.root}`, speed, {
                width: '+=20',
                x: '+=20',
                ease,
            }),
            TweenMax.to(`.${styles.arrowRight}`, speed, { x: '+=10', ease }),
            TweenMax.to(`.${styles.label}`, speed, { x: '+=20', ease }),
        ]);

        animation.add([
            TweenMax.to(`.${styles.root}`, speed, {
                width: '-=20',
                x: '-50%',
                ease,
            }),
            TweenMax.to(`.${styles.arrowRight}`, speed, { x: 0, ease }),
            TweenMax.to(`.${styles.label}`, speed, { x: 0, ease }),
        ]);

        return () => {
            animation.kill();
        };
    });

    useEffect(() => {
        if (!isVisible) {
            TweenMax.to(`.${styles.root}`, 0.3, {
                autoAlpha: 0,
                ease: Power2.easeIn,
                onComplete: () => {
                    animation && animation.kill && animation.kill();
                },
            });
        }
    }, [isVisible]);

    return (
        <div className={styles.root}>
            <Arrow className={styles.arrowLeft} />
            <Text className={styles.label} as="span" theme="eyebrows">
                Swipe
            </Text>
            <Arrow className={styles.arrowRight} />
        </div>
    );
};

SwipeIndicator.propTypes = {
    isVisible: PropTypes.bool,
};

SwipeIndicator.defaultProps = {
    isVisible: true,
};

export default SwipeIndicator;
