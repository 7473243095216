import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import FeaturedLink from 'components/ui/FeaturedLink';
import Text from 'components/ui/Text';

import grid from 'styles/grid.scss';
import styles from './Careers.scss';

const Careers = ({ title, body, link }) => (
    <section className={cx(grid.container, styles.careers)}>
        <Text as="h3" theme="callout" className={styles.title}>
            {title}
        </Text>
        <Text as="p" theme="bodyCopy" className={styles.description}>
            {body}
        </Text>
        <div className={styles.link}>
            <FeaturedLink href={link} target="_blank">
                Job Opportunities
            </FeaturedLink>
        </div>
    </section>
);

Careers.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default Careers;
