export const headerStatuses = {
    hidden: 'hidden',
    transitionUp: 'transitionUp',
    scrolling: 'scrolling',
    default: 'default',
};

export const headerViews = {
    default: 'default',
    work: 'work',
};

export const workViews = {
    carousel: 'carousel',
    list: 'list',
};

export const tunnels = {
    header: 'header',
};

export const gaTag = 'UA-17119615-1';
