import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Logo.scss';

const Logo = ({ className }) => {
    return (
        <svg
            viewBox="0 0 78 20"
            version="1.1"
            className={cx(styles.logo, className)}
        >
            <defs>
                <path
                    fill="currentColor"
                    id="a"
                    d="M.026.025h15.821v18.688H.027z"
                />
            </defs>
            <g>
                <g transform="translate(0 .952)">
                    <path
                        fill="currentColor"
                        d="M0 18.713V.025h4.202v7.382h7.443V.025h4.202v18.688h-4.202v-7.398H4.202v7.398H0"
                        mask="url(#b)"
                    />
                </g>
                <path
                    fill="currentColor"
                    d="M25.96 12.7h5.047l-2.54-7.446-2.507 7.445zM19.048 20l7.1-19.048h4.847L38.095 20H33.49l-1.31-3.727h-7.391L23.515 20h-4.467zM40.952 12.367V.952h4.39v10.979c0 1.498.295 2.281.883 2.918.588.637 1.525 1.178 2.81 1.178 1.285 0 2.222-.54 2.81-1.178.588-.637.883-1.42.883-2.918V.952h4.415v11.415c0 4.941-2.88 7.633-8.108 7.633-5.228 0-8.083-2.692-8.083-7.633M60.952 13.871h4.49c.093.985.507 1.448 1.273 1.93.766.48 1.717.72 2.855.72 1.02 0 1.534-.24 2.125-.615.455-.288.885-.769.885-1.433 0-1.054-.57-1.778-2.263-2.237l-3.9-1.03c-3.342-.893-5.012-2.702-5.012-5.428 0-1.9.719-3.46 2.158-4.387C65.002.463 66.777 0 68.889 0c2.204 0 4.009.515 5.413 1.546 1.404 1.03 2.187 2.473 2.35 4.487h-4.456c0-.766-.387-1.447-1.048-1.905-.662-.458-1.52-.606-2.518-.606-.905 0-1.496.161-1.933.485-.478.354-.705.844-.705 1.462 0 .527.191.962.574 1.306.383.343.86.641 1.766.893l3.899 1.03c3.365.894 4.912 2.7 4.912 5.448 0 1.992-.75 3.25-2.172 4.35C73.556 19.592 71.592 20 69.341 20c-2.46 0-4.432-.544-5.918-1.632-1.484-1.087-2.309-2.504-2.47-4.497"
                />
            </g>
        </svg>
    );
};
Logo.propTypes = {
    className: PropTypes.string,
};

export default Logo;
