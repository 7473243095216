import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'routes';

import Text from 'components/ui/Text';

import styles from './FeaturedLink.scss';

const FeaturedLink = React.forwardRef((props, ref) => {
    const { href, className, children, onClick, ...otherProps } = props;
    const classNames = cx(styles.featuredLink, className);
    if (href.match(/^(http|https|mailto|ftp|tel):/)) {
        return (
            <Text
                as="a"
                theme="eyebrows"
                className={classNames}
                ref={ref}
                href={href}
                {...otherProps}
            >
                {children}
            </Text>
        );
    }
    return (
        <Link passHref route={href} {...otherProps}>
            <Text
                as="a"
                theme="eyebrows"
                className={classNames}
                ref={ref}
                onClick={onClick}
            >
                {children}
            </Text>
        </Link>
    );
});

FeaturedLink.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

FeaturedLink.defaultProps = {
    className: '',
};

export default FeaturedLink;
