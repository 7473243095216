/*
 * KeyPhoto.js
 * ===========
 * Showcase photo on right side.
 * Transitions between images to show different people.
 * Takes morphing texture created by RippleGen to apply distortions.
 */

import * as THREE from 'three';
import { TweenLite, Power2 } from 'gsap';

import { isMinScreenSize } from 'utils';

import vShader from './shaders/keyPhoto.vert';
import fShader from './shaders/keyPhoto.frag';

import { zTween } from 'utils';

export default class KeyPhoto {
    isTablet = isMinScreenSize('tablet');
    isTabletLandscape = isMinScreenSize('tabletLandscape');
    isDesktop = isMinScreenSize('desktop');

    constructor(_subdivs, _renderer, _viewport) {
        this.subdivs = _subdivs;
        this.renderer = _renderer;
        this.viewport = _viewport;

        this.texAlternation = 0;
        this.visible = false;

        // Scene stuff
        this.scene = new THREE.Scene();

        this.cam = new THREE.PerspectiveCamera(50, 0.75);
        this.cam.position.set(0, 0, 15);

        this.cam.matrixAutoUpdate = false;
        this.cam.updateMatrix();

        this.tex1 = new THREE.Texture();
        this.tex1.minFilter = THREE.LinearFilter;
        this.tex2 = new THREE.Texture();
        this.tex2.minFilter = THREE.LinearFilter;

        // Build plane
        const geom = new THREE.PlaneBufferGeometry(
            6,
            8,
            this.subdivs.x,
            this.subdivs.y
        );
        const material = new THREE.RawShaderMaterial({
            uniforms: {
                map0: { value: this.tex1 },
                map1: { value: this.tex2 },
                heightmap: { value: null },
                transition: { value: 0 },
                viz: { value: 0 },
            },
            defines: {
                SHADER_NAME: 'KeyPhotoMaterial',
            },
            vertexShader: vShader,
            fragmentShader: fShader,
            transparent: true,
        });
        this.plane = new THREE.Mesh(geom, material);
        this.scene.add(this.plane);

        // Uniform shortcuts
        this.uniMap0 = material.uniforms.map0;
        this.uniMap1 = material.uniforms.map1;
        this.uniHeight = material.uniforms.heightmap;
        this.uniTrans = material.uniforms.transition;
        this.uniViz = material.uniforms.viz;
    }

    onWindowResize() {
        this.isTablet = isMinScreenSize('tablet');
        this.isTabletLandscape = isMinScreenSize('tabletLandscape');
        this.isDesktop = isMinScreenSize('desktop');
    }

    // ******************* PUBLIC METHODS ******************* //
    // Image texture is ready
    show(_img) {
        if (this.texAlternation === 0) {
            this.tex2.image = _img;
            this.tex2.needsUpdate = true;
            this.texAlternation = 1;
        } else {
            this.tex1.image = _img;
            this.tex1.needsUpdate = true;
            this.texAlternation = 0;
        }

        // Animate in if first time
        if (this.visible === false) {
            this.visible = true;
            this.uniTrans.value = this.texAlternation;
            TweenLite.to(this.uniViz, 1.0, {
                value: 1,
                ease: Power2.easeInOut,
                delay: 0.7,
            });
        }
    }

    hide() {
        TweenLite.killTweensOf(this.uniViz);
        TweenLite.to(this.uniViz, 1.0, {
            value: 0,
            ease: Power2.easeOut,
            onComplete: () => {
                this.visible = false;
            },
        });
    }

    // Deallocate memory from GPU
    dispose() {
        this.scene.remove(this.plane);
        this.plane.geometry.dispose();
        this.plane.material.dispose();
        this.tex1.dispose();
        this.tex2.dispose();
    }

    // ******************* UPDATE ******************* //
    update(_heightMap) {
        if (this.uniViz.value > 0) {
            this.uniTrans.value = zTween(
                this.uniTrans.value,
                this.texAlternation,
                0.1
            );
            this.uniHeight.value = _heightMap;

            let width, height, x, y;

            if (this.isTabletLandscape) {
                // > 1024, viewport is half the browser width
                width = this.viewport.x / 2;

                // max width for large desktop sizes
                if (width > 1600) width = 720;
            } else if (this.isTablet) {
                // tablet, browser width discounting the tablet margin
                width = this.viewport.x - 144;
            } else {
                // mobile, a little more than browser width discounting mobile margin
                width = this.viewport.x * 1.15 - 64;
            }

            if (this.isTabletLandscape) {
                // tablet landscape up, picture is aligned to the right
                x = this.viewport.x / 2;
            } else {
                // other sizes align picture to the center
                x = this.viewport.x / 2 - width / 2;
            }

            // get height multiplying by picture aspect ratio
            height = width / 0.75;

            // tweak y offset position

            if (this.isDesktop) {
                y = this.viewport.y / 2 - height / 2;
            } else if (this.isTabletLandscape) {
                y = (this.viewport.y * 0.86) / 2 - height / 2;
            } else if (this.isTablet) {
                y = -70;
            } else {
                // Different offset center for taller devices
                y = this.viewport.y >= 635 ? -20 : -70;
            }

            this.renderer.setViewport(x, y, width, height);
            this.renderer.render(this.scene, this.cam);
        }
    }
}
