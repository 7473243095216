import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';

import styles from './PlayButton.scss';

const PlayButton = ({ style }) => (
    <div className={styles.button}>
        <SvgIcon iconType={`play_${style}`} />
    </div>
);

PlayButton.propTypes = {
    style: PropTypes.string,
};

PlayButton.defaultProps = {
    style: 'opaque',
};

export default PlayButton;
