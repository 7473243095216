import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './Result.scss';

const Stats = ({ answers, changeVideo }) => {
    const [pct, setPct] = useState(['50%', '50%']);

    const opt1El = useRef(null);
    const opt2El = useRef(null);

    const [statsHeight, setStatsHeight] = useState(`auto`);
    const displayMode = statsHeight === 'auto' ? 'flex' : 'block';

    useEffect(() => {
        const countOne = Number(answers[0].answerCount);
        const countTwo = Number(answers[1].answerCount);
        const total = countOne + countTwo;

        setPct([
            `${Math.round((countOne / total) * 100)}%`,
            `${Math.round((countTwo / total) * 100)}%`,
        ]);
    }, [answers]);

    useEffect(() => {
        const h1 = opt1El.current.clientHeight;
        const h2 = opt2El.current.clientHeight;

        if (h1 !== h2) {
            const height = h2 > h1 ? h2 : h1;
            setStatsHeight(`${height}px`);
        }
    });

    return (
        <div
            className={styles.stats}
            style={{
                height: statsHeight,
            }}
        >
            <div
                ref={opt1El}
                style={{
                    width: pct[0],
                    height: statsHeight,
                    display: displayMode,
                }}
                className={styles.option1}
                onClick={() => changeVideo(0)}
            >
                <Text className={styles.pct} theme="links">
                    {pct[0]}
                </Text>
                <Text className={styles.name} theme="links">
                    {answers[0].title}
                </Text>
            </div>
            <div
                ref={opt2El}
                style={{
                    width: pct[1],
                    height: statsHeight,
                    display: displayMode,
                }}
                className={styles.option2}
                onClick={() => changeVideo(1)}
            >
                <Text className={styles.pct} theme="links">
                    {pct[1]}
                </Text>
                <Text className={styles.name} theme="links">
                    {answers[1].title}
                </Text>
            </div>
        </div>
    );
};

Stats.propTypes = {
    answers: PropTypes.array.isRequired,
    changeVideo: PropTypes.func.isRequired,
};

export default Stats;
