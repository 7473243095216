import React from 'react';
import PropTypes from 'prop-types';
import { TweenMax, Power1, Power3 } from 'gsap';

import Text from 'components/ui/Text';

import styles from './Headline.scss';

class Headline extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        fontsLoaded: PropTypes.bool.isRequired,
    };

    ref = React.createRef();

    componentDidMount() {
        if (this.props.fontsLoaded) {
            this.animate();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.fontsLoaded && this.props.fontsLoaded) {
            this.animate();
        }
    }

    componentWillUnmount() {
        this.slideTweens.forEach(tween => tween.kill());
        this.fadeTweens.forEach(tween => tween.kill());
    }

    animate() {
        const SplitText = require('utils/gsap/SplitText');
        this.splitText = new SplitText(this.ref.current, {
            type: 'lines',
            linesClass: styles.lines,
        });
        TweenMax.set(this.ref.current, {
            opacity: 1,
        });
        this.slideTweens = TweenMax.staggerFrom(
            this.splitText.lines,
            1,
            { y: 50, ease: Power3.easeOut, delay: 0.5 },
            0.07,
            this.staggerDone
        );
        this.fadeTweens = TweenMax.staggerTo(
            this.splitText.lines,
            0.5,
            { opacity: 1, ease: Power1.easeIn, delay: 0.5 },
            0.07
        );
    }

    staggerDone = () => {
        this.splitText.revert();
    };

    render() {
        const { children } = this.props;
        return (
            <Text
                as="h1"
                theme="landingHeadline"
                className={styles.headline}
                ref={this.ref}
            >
                {children}
            </Text>
        );
    }
}

export default Headline;
