// @flow
import React from 'react';
import PropTypes from 'prop-types';

import icons from './icons';
import styles from './SvgIcon.scss';

const SvgIcon = ({ iconType, height, tag, width }) => {
    return React.createElement(tag, {
        className: styles.root,
        dangerouslySetInnerHTML: { __html: icons[iconType] },
        style: {
            width,
            height,
        },
    });
};

SvgIcon.defaultProps = {
    tag: 'span',
};

SvgIcon.propTypes = {
    height: PropTypes.string,
    iconType: PropTypes.string,
    tag: PropTypes.string,
    width: PropTypes.string,
};

export default SvgIcon;
