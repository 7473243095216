import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TunnelPlaceholder } from 'react-tunnels';
import { Transition, TransitionGroup } from 'react-transition-group';

import { tunnels } from 'utils/constants';

import Burger from './Burger';
import Nav from './Nav';
import Logo from './Logo';

import grid from 'styles/grid.scss';
import styles from './Header.scss';

class Header extends Component {
    renderTunnel() {
        return (
            <TunnelPlaceholder id={tunnels.header}>
                {item => (
                    <TransitionGroup
                        component="section"
                        className={styles.tunnelContainer}
                    >
                        {item.children && (
                            <Transition timeout={800}>
                                {state => (
                                    <div
                                        className={cx(styles.tunnelItem, {
                                            [styles.tunnelItemExiting]:
                                                state === 'exiting',
                                            [styles.tunnelItemEntering]:
                                                state === 'entering',
                                        })}
                                    >
                                        {item.children}
                                    </div>
                                )}
                            </Transition>
                        )}
                    </TransitionGroup>
                )}
            </TunnelPlaceholder>
        );
    }

    render() {
        const { isAnchored, isMenuOpen, isSticky, onClick } = this.props;

        const headerStyles = cx(styles.header, grid.container, {
            [styles.isAnchored]: isAnchored,
            [styles.isSticky]: isSticky,
        });

        return (
            <header className={headerStyles}>
                <Logo />
                {this.renderTunnel()}
                <Nav isActive={isMenuOpen} onClick={onClick} />
                <Burger isActive={isMenuOpen} onClick={onClick} />
            </header>
        );
    }
}

Header.propTypes = {
    isAnchored: PropTypes.bool,
    isMenuOpen: PropTypes.bool,
    isSticky: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

export default Header;
