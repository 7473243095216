import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';

import grid from 'styles/grid.scss';
import styles from './Error.scss';

const Error = ({ statusCode }) => {
    return (
        <main className={cx(styles.error, grid.container)}>
            <Text
                as="h1"
                theme="landingHeadline"
                className={styles.headline}
            >
                {statusCode ? `${statusCode}` : '500'}
            </Text>
        </main>
    );
};

Error.propTypes = {
    statusCode: PropTypes.number,
};

export default Error;
