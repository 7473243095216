import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { modulo } from 'utils';

import { TweenMax, Linear } from 'gsap';
import Draggable from 'gsap/Draggable';
import ThrowPropsPlugin from 'utils/gsap/ThrowPropsPlugin';
import ModifiersPlugin from 'utils/gsap/ModifiersPlugin';

import styles from './GrabGallery.scss';

// prevent tree shaking
const throwPropsPlugin = ThrowPropsPlugin; // eslint-disable-line
const modifiersPlugin = ModifiersPlugin; // eslint-disable-line

class GrabGallery extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        autoScrollSpeed: PropTypes.number, // Pixels per second (0: off)
        className: PropTypes.string,
        listClassName: PropTypes.string,
    };

    static defaultProps = {
        autoScrollSpeed: 75,
    };

    wrapperRef = React.createRef();
    listRef = React.createRef();

    componentDidMount() {
        this.observer = new IntersectionObserver(this.handleIntersection, {});
        this.observer.observe(this.wrapperRef.current);
        this.initializeCarousel();
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    initializeCarousel = () => {
        this.draggableDiv = document.createElement('div');

        window.addEventListener('resize', this.handleResize);

        if (this.listRef.current == null) {
            return;
        }

        // Calculate each item's size
        const elements = Array.from(this.listRef.current.children);
        this.items = elements.map(el => {
            const rect = el.getBoundingClientRect();
            return {
                el,
                width: rect.width,
                height: rect.height,
            };
        });

        // Calculate total width of carousel and max size of items
        this.sizes = this.items.reduce(
            (acc, item) => ({
                totalWidth: acc.totalWidth + item.width,
                maxItemWidth: Math.max(acc.maxItemWidth, item.width),
                maxItemHeight: Math.max(acc.maxItemHeight, item.height),
            }),
            { totalWidth: 0, maxItemWidth: 0, maxItemHeight: 0 }
        );
        const { totalWidth, maxItemWidth } = this.sizes;

        // Calculate x-coords to center items on screen
        let widths = 0;
        this.items.forEach((item, i, items) => {
            item.xCenter =
                maxItemWidth +
                (window.innerWidth - items[i].width) / 2 -
                widths;
            widths += items[i].width;
        });

        // Set size and position of <ul> container, and show it
        TweenMax.set(this.listRef.current, {
            x: -maxItemWidth,
            // height: maxItemHeight,
            // yPercent: -50,
            visibility: 'visible',
        });

        // This infinitely repeating animation doesn't do anything by itself.
        // It is driven by `updateProgress()` which calls `progress()` on this.
        // `updateProgress()` itself is driven by Draggable.
        // The actual positioning of the carousel items happens in the
        // modifier, `xModifier`.
        const { autoScrollSpeed } = this.props;
        const duration = autoScrollSpeed > 0 ? totalWidth / autoScrollSpeed : 5;
        this.animation = TweenMax.to(this.items, duration, {
            x: '-=' + totalWidth,
            ease: Linear.easeNone,
            paused: autoScrollSpeed <= 0,
            repeat: -1,
            modifiers: {
                x: this.xModifier,
            },
        });

        this.draggable = new Draggable(this.draggableDiv, {
            type: 'x',
            throwProps: true,
            trigger: this.wrapperRef.current,
            onPress: this.handleDraggablePress,
            onClick: this.handleDraggableClick,
            onDragStart: this.handleDraggableDragStart,
            onDrag: this.handleDraggableDrag,
            onThrowUpdate: this.handleDraggableThrowUpdate,
            allowEventDefault: true,
            allowContextMenu: true,
            dragClickables: true,
            onThrowComplete: () => {
                if (this.props.autoScrollSpeed > 0) {
                    this.animation.resume();
                }
            },
        });

        // Move items to their initial positions
        let x = this.items[0].xCenter;
        this.items.forEach(item => {
            item.x = this.xModifier(x, item);
            x += item.width;
        });
    };

    killCarousel = () => {
        window.removeEventListener('resize', this.handleResize);
        this.animation && this.animation.kill();
        this.draggable && this.draggable.kill();
    };

    handleResize = () => {
        this.killCarousel();
        this.initializeCarousel();
    };

    handleIntersection = ([entry]) => {
        if (entry && this.props.autoScrollSpeed > 0) {
            if (entry.isIntersecting) {
                this.animation.resume();
            } else {
                this.animation.pause();
            }
        }
    };

    xModifier = (x, item) => {
        // This does the actual positioning of the items.
        // Called by Draggable for each item.
        const { totalWidth, maxItemWidth } = this.sizes;
        x = modulo(x, totalWidth);
        const inView = x - item.width <= window.innerWidth + maxItemWidth;
        const visibility = inView ? 'visible' : 'hidden';
        TweenMax.set(item.el, { x, visibility });
        return x;
    };

    handleDraggablePress = () => {
        // Preparing for a drag operation: set start values
        this.initProgress();
        if (this.props.autoScrollSpeed > 0) {
            this.animation.pause();
        }
    };

    handleDraggableClick = () => {
        // We expected a drag operation but in the end it was just a click
        if (this.props.autoScrollSpeed > 0) {
            this.animation.resume();
        }
    };

    handleDraggableDragStart = () => {
        // Drag operation starts
    };

    handleDraggableDrag = () => {
        this.updateProgress();
    };

    handleDraggableThrowUpdate = () => {
        this.updateProgress();
    };

    initProgress() {
        // Initialize drag- and wheel-throws
        const { totalWidth } = this.sizes;
        this.xMin = this.draggable.x;
        this.xMax = this.xMin - totalWidth;
        this.progress = this.animation.progress();
    }

    updateProgress() {
        // This drives the animation.
        const delta = this.normalize(this.draggable.x);
        this.animation.progress(this.progress + delta);
    }

    normalize(x) {
        return (x - this.xMin) / (this.xMax - this.xMin);
    }

    render() {
        const { children, className, listClassName } = this.props;
        const galleryClass = cx(styles.gallery, className);
        const listClass = cx(styles.list, listClassName);
        return (
            <section ref={this.wrapperRef} className={galleryClass}>
                <ul ref={this.listRef} className={listClass}>
                    {children}
                </ul>
            </section>
        );
    }
}

export default GrabGallery;
