import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Sticky.scss';

class Sticky extends Component {
    static propTypes = {
        children: PropTypes.func,
        isMenuOpen: PropTypes.bool.isRequired,
        scrollY: PropTypes.number.isRequired,
    };

    state = {
        isAnchored: true,
        isPeeking: false,
        isSticky: false,
    };

    yDeltaThreshold = 7; //amount of scroll before nav reappears
    lastValue = 0;

    componentDidMount() {
        // get height on mount
        this.headerHeight = document.querySelector(
            `.${styles.root}`
        ).offsetHeight;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.scrollY !== this.props.scrollY) {
            this.handleScroll();
        }
    }

    handleScroll = () => {
        const { scrollY } = this.props;

        const initialValue = scrollY;
        const lastValue = this.lastValue;

        this.updateStickyState(scrollY, initialValue, lastValue);
    };

    updateStickyState = (scrollY, initialValue, lastValue) => {
        const nextState = { ...this.state };

        if (initialValue > lastValue && initialValue > this.headerHeight) {
            // down
            nextState.isPeeking = false;

            //////////////////////////////////////////////////////////////////
            // every time we go from `isAnchored` to `!isAnchored` we don't want the transition
            // that isSticky usually applies. isSticky remains true after this so that we get
            // transitions when `isPeeking` is toggled
            //////////////////////////////////////////////////////////////////
            if (scrollY > this.headerHeight && this.state.isAnchored) {
                nextState.isAnchored = false;
                nextState.isSticky = true;
            }
        } else {
            // up
            if (
                Math.abs(lastValue - initialValue) <= this.yDeltaThreshold &&
                scrollY !== 0 &&
                initialValue > this.headerHeight
            ) {
                return;
            }

            nextState.isPeeking = true;
        }

        this.setState(
            {
                isPeeking: nextState.isPeeking,
            },
            () => {
                if (nextState.isPeeking && scrollY <= 15) {
                    nextState.isAnchored = true;
                    nextState.isSticky = false;
                }

                this.setState({
                    ...nextState,
                });
            }
        );

        this.lastValue = initialValue;
    };

    render() {
        const { isPeeking, isAnchored, isSticky } = this.state;
        const { children, isMenuOpen } = this.props;

        const stickyClasses = cx(styles.root, {
            [styles.isPeeking]: isPeeking,
            [styles.isAnchored]: isAnchored,
            [styles.isSticky]: isSticky,
        });

        return (
            <div className={stickyClasses}>
                {children({ ...this.state, isMenuOpen })}
            </div>
        );
    }
}

export default Sticky;
