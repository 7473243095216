import React from 'react';
import PropTypes from 'prop-types';
import { TweenMax, Power1 } from 'gsap';
import random from 'lodash/random';

import styles from './Interstitial.scss';

class GradientSlide extends React.Component {
    static props = {
        image: PropTypes.string.isRequired,
        gradient: PropTypes.array.isRequired,
    };

    backgroundRef = React.createRef();

    componentDidMount() {
        this.animateGradient();
    }

    componentWillUnmount() {
        this.gradientTween.kill();
    }

    animateGradient = () => {
        const gradientX = random(0, 100);
        const gradientY = random(0, 100);

        this.gradientTween = TweenMax.to(this.backgroundRef.current, 6, {
            backgroundPositionX: `${gradientX}%`,
            backgroundPositionY: `${gradientY}%`,
            ease: Power1.easeInOut,
            onComplete: this.animateGradient,
        });
    };

    render() {
        const { image, gradient } = this.props;
        const [c1, c2] = gradient;
        const style = {
            backgroundColor: c2,
            backgroundImage: `linear-gradient(270deg, ${c1} 0%, ${c2} 100%)`,
        };

        return (
            <div
                ref={this.backgroundRef}
                className={styles.gradientSlide}
                style={style}
            >
                <img src={image} />
            </div>
        );
    }
}

export default GradientSlide;
