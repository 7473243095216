/*
 * TileManager.js
 * ===========
 * Contains all instances of Tile.
 * Manages all tiles based on events from PeopleView.
 * Creates a grid of 4 rows, and X cols, based on peeps.length
 */

import * as THREE from 'three';
import { TweenLite, Power2 } from 'gsap';

import { isTouchDevice } from 'utils';

import ColorTile from './ColorTile';
import Tile from './Tile';

export default class TileManager {
    static get ZOOM_IN() {
        return isTouchDevice() ? 5 : 3;
    }
    static get ZOOM_OUT() {
        return isTouchDevice() ? 5 : 2.5;
    }

    rows; // Whole rows in grid, always 4
    cols; // Whole columns in grid
    hover; // X: col, Y: row of hovered tile
    iSelected; // Index of selected person (-1 means list view)
    iHovered; // Index of hovered person (-1 means none)
    all; // Array of Tiles
    movingTiles; // Array of Tiles, populated when re-stacking needed
    shufflePos; // Array of pos, to shuffle tiles when re-stacking

    constructor(_people, _renderer, _viewport) {
        this.people = _people;
        this.renderer = _renderer;
        this.viewport = _viewport;

        // Grid properties
        this.rows = isTouchDevice() ? 6 : 4;
        this.cols = Math.ceil((this.people.length * 2) / this.rows);

        this.hover = new THREE.Vector2(-1, -1);
        this.iSelected = -1;
        this.iHovered = -1;

        this.all = new Array(this.rows * this.cols);
        this.movingTiles = new Array();
        this.shufflePos = new Array();

        // Panning
        this.panPrev = new THREE.Vector2();
        this.panTarget = new THREE.Vector2();
        this.panReal = new THREE.Vector2();
        this.mousePos = new THREE.Vector2(-1, -1);
        this.pointerDown = false;
        this.panSpeed = isTouchDevice() ? 1.5 : 1;

        // Scene setup
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0x1c1c1c);

        this.cam = new THREE.OrthographicCamera(0, 1, 1, 0, -1, 1);
        this.updateCam(TileManager.ZOOM_OUT);

        this.bounds = {
            maxY: 0,
            minY: 0,
            minX: 0,
            maxX: 0,
        };
    }

    // ******************* PRIVATE METHODS ******************* //
    // Returns index in all[] from X, Y coords
    getIndex(_x, _y) {
        if (_x.isVector2) {
            _y = _x.y;
            _x = _x.x;
        }

        for (let i = 0; i < this.all.length; i++) {
            if (this.all[i] && this.all[i].x === _x && this.all[i].y === _y) {
                return i;
            }
        }

        return -1;
    }

    camZoom(_cellHeight) {
        TweenLite.to(this.cam, 0.7, {
            cellHeight: _cellHeight,
            onUpdate: () => {
                this.updateCam(-1);
            },
            ease: Power2.easeOut,
        });
    }

    // ******************* PUBLIC METHODS ******************* //
    // Builds grid to fill all rows & cols
    buildGrid() {
        this.viewport.setRowsCols(this.rows, this.cols);

        return {
            rows: this.rows,
            cols: this.cols,
        };
    }

    addToGrid(startIndex, people, gridTexture) {
        const totalPeeps = this.people.length;
        const totalSlots = this.all.length;

        people.forEach((person, i) => {
            for (let j = i + startIndex; j < totalSlots; j += totalPeeps) {
                const x = Math.floor(j / this.rows);
                const y = j % this.rows;

                this.all[j] = new Tile(
                    x,
                    y,
                    person,
                    gridTexture.getTexture(),
                    this.viewport
                );
                this.scene.add(this.all[j].plane);
            }
        });

        this.all.map(person => person.introFade());
    }

    addColorToGrid(startIndex, people) {
        const totalPeeps = this.people.length;
        const totalSlots = this.all.length;

        people.forEach((person, i) => {
            for (let j = i + startIndex; j < totalSlots; j += totalPeeps) {
                const x = Math.floor(j / this.rows);
                const y = j % this.rows;

                const color = new ColorTile(x, y, person, this.viewport);
                this.scene.add(color.plane);
            }
        });
    }

    // All tiles fade in
    playIntro() {
        this.all.forEach(_tile => _tile.introFade());
    }

    // All photos show same person
    enterCloseup(_index, _direction) {
        this.iSelected = _index;
        const dir = _direction !== undefined ? _direction : 1;
        const texLocation = this.people[this.iSelected].texLocation;

        if (dir === 1) {
            this.all.forEach(_tile => _tile.swipeRight(texLocation));
        } else {
            this.all.forEach(_tile => _tile.swipeLeft(texLocation));
        }

        // Remove mouseover state
        if (this.iSelected < this.all.length) {
            this.all.forEach(_tile => _tile.mouseOut());
        }
    }

    // All photos return to list view
    exitCloseup() {
        if (this.iSelected === -1) {
            return;
        }
        this.iSelected = -1;
        this.mousePos.set(-1, -1);

        this.all.forEach(_tile => _tile.exitCloseup());
    }

    // Update camera and custom properties
    updateCam = _cellHeight => {
        // when -1, cellHeight is controlled by tween
        if (_cellHeight !== -1) {
            // Otherwise it's just setting the camZoom manually
            this.cam.cellHeight =
                _cellHeight !== undefined ? _cellHeight : TileManager.ZOOM_OUT;
        }

        this.cam.worldHeight = this.cam.cellHeight * Tile.HEIGHT;

        // Update camera projection
        const halfHeight = this.cam.worldHeight / 2;
        this.cam.top = halfHeight;
        this.cam.bottom = -halfHeight;
        this.cam.left = -halfHeight * this.viewport.r;
        this.cam.right = halfHeight * this.viewport.r;

        // width/height in cell units, instead of world units
        this.cam.cellWidth = this.cam.right * 2;
        this.cam.worldWidth = this.cam.cellWidth * Tile.WIDTH;

        // how far origin is offset, used for mouseovers
        this.cam.offsetY = (this.rows - this.cam.cellHeight) / 2;
        this.cam.offsetX = (this.cols - this.cam.cellWidth) / 2;
        this.cam.updateProjectionMatrix();

        // Updates VP regional variable
        this.viewport.setCamRange(this.cam.cellHeight, this.cam.cellWidth);
    };

    // Deallocate memory from GPU
    dispose() {
        this.all.forEach((_tile, _index) => {
            this.scene.remove(_tile.plane);
            _tile.dispose();
            this.all[_index] = null;
        });
    }

    // ******************* GESTURES ******************* //
    // Mouse hover animation
    onMouseMove(_x, _y) {
        this.mousePos.set(_x, _y);
    }

    onMouseOut() {
        this.mousePos.set(-1, -1);
    }

    // Zooms out when touch ends
    onPressEnd() {
        this.pointerDown = false;

        if (this.iSelected !== -1) {
            return;
        }

        this.camZoom(TileManager.ZOOM_OUT);

        this.all.forEach(tile => {
            this.onMouseOut();
            tile.zoomTo(Tile.ZOOM_SM);
        });
    }

    onPanStart(_x, _y) {
        this.panPrev.set(_x, _y);
        this.pointerDown = true;

        if (this.iSelected !== -1) {
            return;
        }

        this.camZoom(TileManager.ZOOM_IN);

        const amount = isTouchDevice() ? Tile.ZOOM_SM : Tile.ZOOM_NONE;

        this.all.forEach(tile => {
            tile.zoomTo(amount, this.hover.x, this.hover.y);
        });
    }

    onPan(_x, _y) {
        // Transforms on-screen pixels to 3D world units
        this.panTarget.x +=
            ((this.panPrev.x - _x) / this.viewport.x) * this.cam.worldWidth;
        this.panTarget.y -=
            ((this.panPrev.y - _y) / this.viewport.y) * this.cam.worldHeight;

        this.panPrev.set(_x, _y);
    }

    onPanEnd(_x, _y) {
        this.panPrev.set(_x, _y);
    }

    // Returns photo index that was clicked
    onClick(_x, _y) {
        // Returns value if none active
        if (this.iSelected === -1) {
            if (this.iHovered === -1) {
                this.mousePos.set(_x, _y);
                this.updateMouseOver();
            }

            if (this.iHovered !== -1) {
                return this.all[this.iHovered].person.texLocation;
            }
        }

        return -1;
    }

    setPosition(_x, _y) {
        this.panTarget.set(_x, _y);
        this.panReal.set(_x, _y);
        this.cam.position.set(this.panReal.x, this.panReal.y, 0);
    }

    // ******************* UPDATE LOOP ******************* //
    updateMouseOver() {
        if (this.iSelected !== -1 || this.pointerDown) {
            return;
        }

        // Lowlight old if mouse leaves screen
        if (this.mousePos.x <= 0 || this.mousePos.y <= 0) {
            if (this.iHovered !== -1) {
                this.all[this.iHovered].mouseOut();
                this.iHovered = -1;
                this.hover.set(-1, -1);
            }
            return;
        }

        // Calculating displacement of pan...
        let newX = this.panReal.x + this.cam.offsetX;
        let newY = this.panReal.y * Tile.INV_HEIGHT + this.cam.offsetY;
        // ...add to mouse coords
        newX = Math.floor(this.mousePos.x * this.cam.cellWidth + newX);
        newY = Math.floor((1.0 - this.mousePos.y) * this.cam.cellHeight + newY);

        // Swap if changed
        if (newX !== this.hover.x || newY !== this.hover.y) {
            // Lowlight old
            if (this.iHovered !== -1) {
                this.all[this.iHovered].mouseOut();
            }

            // Highlight new
            this.iHovered = this.getIndex(newX, newY);
            if (this.iHovered !== -1) {
                this.all[this.iHovered].mouseOver();
            }

            this.hover.set(newX, newY);
        }
    }

    // True if grid bounds has changed due to panning
    updateGridBounds() {
        const p = this.panReal;

        // Min,max x-axis
        const maxX = Math.floor(p.x + this.cols - 0.5);
        const minX = Math.ceil(p.x - 0.5);
        // Min,max y-axis
        const maxY = Math.floor(p.y * Tile.INV_HEIGHT + this.rows - 0.5);
        const minY = Math.ceil(p.y * Tile.INV_HEIGHT - 0.5);

        if (maxX !== this.bounds.maxX || maxY !== this.bounds.maxY) {
            const midX = (maxX + minX) / 2;
            const midY = (maxY + minY) / 2;
            this.bounds = { maxX, minX, midX, maxY, minY, midY };

            this.viewport.setGrid(this.bounds);

            return true;
        }

        return false;
    }

    // Loop through tiles, place them back in bounds
    reStackTiles() {
        const b = this.bounds;
        let destX;
        let destY;

        this.movingTiles = [];
        this.shufflePos = [];

        this.all.forEach(tile => {
            // Check x bounds
            if (tile.x < b.minX) {
                destX = tile.x + this.cols;
            } else if (tile.x > b.maxX) {
                destX = tile.x - this.cols;
            }

            // Check y bounds
            if (tile.y < b.minY) {
                destY = tile.y + this.rows;
            } else if (tile.y > b.maxY) {
                destY = tile.y - this.rows;
            }

            // Push to arrays if changed
            if (destX !== undefined || destY !== undefined) {
                this.movingTiles.push(tile);
                this.shufflePos.push({
                    x: destX !== undefined ? destX : tile.x,
                    y: destY !== undefined ? destY : tile.y,
                });
            }

            // Reset for next loop
            destX = destY = undefined;
        });

        // Perform shuffle + change pos
        // Shuffle may cause people to repeat next to each other
        // shuffle(this.shufflePos);

        this.movingTiles.forEach((tile, i) => {
            tile.setPosition(this.shufflePos[i].x, this.shufflePos[i].y);
        });
    }

    update(_t) {
        this.all.forEach(_tile => _tile.update(_t));

        // Cam tweening
        TweenLite.killTweensOf(this.panReal);
        TweenLite.to(this.panReal, 0.5, {
            x: this.panTarget.x * this.panSpeed,
            y: this.panTarget.y,
        });

        this.cam.position.set(this.panReal.x, this.panReal.y, 0);

        this.updateMouseOver();

        // Check if grid needs re-stacking
        if (this.updateGridBounds()) {
            this.reStackTiles();
        }
        this.renderer.render(this.scene, this.cam);
    }
}
