/*
 * PeopleView.js
 * ===========
 * Topmost 3D class. Handles most of its own touch/mouse events.
 * Receives events from React layer, and
 * delegates to KeyPhoto and TileManager accordingly.
 * Also manages RippleGen effect to apply distortions to KeyPhoto
 */

import * as THREE from 'three';
import { TweenLite } from 'gsap';

import KeyPhoto from './KeyPhoto';
import TileManager from './TileManager';
import RippleGen from './RippleGen';
import Renderer from './Renderer';
import GridTexture from './GridTexture';

import Clock from './utilities/Clock';
import PeopleLoader from './utilities/PeopleLoader';
import Viewport from './utilities/Viewport';

import { isTouchDevice, isMinScreenSize } from 'utils';

export default class PeopleView {
    deviceType = 'unknown';
    fpsCap = false;
    preventScroll = false;
    rendering = false;
    zoomMode = false;
    hadInteraction = false;

    constructor(_container, _allPeople, _clickCallback, _interactionCallback) {
        this.container = _container;
        this.people = _allPeople;
        this.clickCallback = _clickCallback;
        this.interactionCallback = _interactionCallback;

        this.deviceType = isTouchDevice() ? 'touch' : 'click';

        this.boundBox = this.container.getBoundingClientRect();

        this.mouse = new THREE.Vector2(-1, -1);
        this.subdivs = new THREE.Vector2(
            3 * Math.pow(2, 4),
            4 * Math.pow(2, 4)
        );

        this.viewportHeight =
            this.deviceType === 'touch'
                ? this.container.clientHeight
                : window.innerHeight;
        this.viewportWidth = window.innerWidth;

        this.viewport = new Viewport();
        this.viewport.set(this.viewportWidth, this.viewportHeight);

        this.renderer = new Renderer();
        this.renderer.setSize(this.viewport.x, this.viewport.y);

        this.container.appendChild(this.renderer.domElement);

        this.gridTex = new GridTexture();

        // Main classes
        this.tileManager = new TileManager(
            this.people,
            this.renderer,
            this.viewport
        );
        this.tileManager.buildGrid();
        this.tileManager.addColorToGrid(0, this.people);

        if (this.deviceType === 'touch') {
            this.tileManager.setPosition(0, 0.67);
        } else {
            this.tileManager.setPosition(0, 0.9);
        }

        window.tileManager = this.tileManager;

        this.clock = new Clock();

        this.keyPhoto = new KeyPhoto(
            this.subdivs,
            this.renderer,
            this.viewport
        );

        this.rippleGen = new RippleGen(this.subdivs, this.mouse, this.renderer);
        this.rippleTex = this.rippleGen.update();

        this.loadPhotosDelay = setTimeout(() => {
            this.loadPhotos();
        }, 1250);

        window.addEventListener('resize', this.onWindowResize);

        // Fire up rendering loop
        this.update(0);
    }

    // ******************* PRIVATE METHODS ******************* //
    // Loads photos, inits events, etthis.container.
    loadPhotos() {
        this.loadingPhotosInitiated = true;

        this.peopleLoader = new PeopleLoader(
            this.people,
            this.gridTex,
            this.tileManager,
            {
                chunkSize: 3,
                pause: 20,
                onChunkComplete: this.handleLoadPhotoChunkComplete,
                onComplete: this.handleLoadPhotosComplete,
            }
        );
        this.peopleLoader.load();

        this.textBox = document.getElementById('text-box');
    }

    handleLoadPhotoChunkComplete = () => {
        // let's skip rendering till all photos are loaded
        // to prevent aditional render calls.
    };

    handleLoadPhotosComplete = () => {
        // Render tiles once images are loaded
        if (!this.rendering) {
            this.render();
        }

        // Mouse events
        if (this.deviceType === 'click') {
            this.container.addEventListener('mousedown', this.onMouseDown);
            this.container.addEventListener('mouseleave', this.onMouseLeave);
            this.container.addEventListener('mouseenter', this.onMouseEnter);
            this.container.addEventListener('mouseup', this.onMouseUp);
        }

        // Touch events
        if (this.deviceType === 'touch') {
            this.container.addEventListener('touchstart', this.onTouchStart);
            this.container.addEventListener('touchmove', this.onTouchMove);
            this.container.addEventListener('touchend', this.onTouchEnd);
            this.container.addEventListener('contextmenu', this.onContextMenu);
        }

        this.container.addEventListener('click', this.onClick);

        this.clearGridTextureCanvas = setTimeout(
            () => this.gridTex.convert(),
            200
        );
    };

    kill() {
        cancelAnimationFrame(this.updateRAF);

        window.removeEventListener('resize', this.onWindowResize);

        // Mouse events
        if (this.deviceType === 'click') {
            this.container.removeEventListener('mousedown', this.onMouseDown);
            this.container.removeEventListener('mouseleave', this.onMouseLeave);
            this.container.addEventListener('mouseenter', this.onMouseEnter);
            this.container.removeEventListener('mouseup', this.onMouseUp);
        }

        // Touch events
        if (this.deviceType === 'touch') {
            this.container.removeEventListener('touchstart', this.onTouchStart);
            this.container.removeEventListener('touchmove', this.onTouchMove);
            this.container.removeEventListener('touchend', this.onTouchEnd);
            this.container.removeEventListener(
                'contextmenu',
                this.onContextMenu
            );
        }

        this.container.removeEventListener('click', this.onClick);

        this.gridTex.dispose();
        this.tileManager.dispose();
        this.keyPhoto.dispose();
        this.rippleGen.dispose();

        clearTimeout(this.loadPhotosDelay);
        clearTimeout(this.clearGridTextureCanvas);

        if (this.loadingPhotosInitiated) this.peopleLoader.dispose();

        this.renderer.renderLists.dispose();
        this.renderer.dispose();
    }

    getXYfromEvent = _event => {
        // Touch event
        if (_event.touches) {
            return {
                x: _event.touches[0].clientX.toFixed(0),
                y: _event.touches[0].clientY.toFixed(0) - this.boundBox.top,
            };
        }

        // Mouse event
        return {
            x: _event.layerX,
            y: _event.layerY,
        };
    };

    onPageScroll = () => {
        // Pause rendering when not in view
        this.boundBox = this.container.getBoundingClientRect();

        const { top } = this.boundBox;

        this.rendering = top <= this.viewport.y && top >= -this.viewport.y;
        this.fpsCap = false;

        // Do not enter "PhotoMode" if scroll occurs
        TweenLite.killDelayedCallsTo(this.enterPhotoMode);
    };

    // ******************* MOUSE GESTURES ******************* //
    // 1. Touch & hold to enter ZoomMode
    onMouseDown = _event => {
        const pos = this.getXYfromEvent(_event);

        TweenLite.killDelayedCallsTo(this.exitZoomMode);

        // we need this delay otherwise click won't trigger
        TweenLite.delayedCall(0.15, this.enterZoomMode, [pos]);
    };

    // 3. Pans when in ZoomMode, highlights when not
    onMouseMove = _event => {
        this.mouse.x = _event.layerX / this.viewport.x;
        this.mouse.y = _event.layerY / this.viewport.y;
        if (this.zoomMode) {
            this.tileManager.onPan(_event.layerX, _event.layerY);
        } else {
            this.tileManager.onMouseMove(this.mouse.x, this.mouse.y);
        }
    };

    // 4. Mouse release schedules exit ZoomMode
    onMouseUp = _event => {
        this.mouse.x = _event.layerX / this.viewport.x;
        this.mouse.y = _event.layerY / this.viewport.y;

        TweenLite.killDelayedCallsTo(this.enterZoomMode);
        // we need this delay otherwise click will trigger once you release the button
        TweenLite.delayedCall(0.1, this.exitZoomMode);
    };

    // 2. Enter ZoomMode, allows panning, disables click
    enterZoomMode = _pos => {
        this.zoomMode = true;
        this.container.style.cursor = 'grabbing';
        this.tileManager.onPanStart(_pos.x, _pos.y);
    };

    // 5. Backgound exits zoom mode, disables panning, allows click
    exitZoomMode = () => {
        this.zoomMode = false;
        this.container.style.cursor = '';
        this.tileManager.onPressEnd();
    };

    // 6. Click opens detailed view if not in ZoomMode
    onClick = _event => {
        this.openDetailView(_event);
    };

    // 7. Whenever the mouse exits the container
    onMouseLeave = () => {
        this.container.removeEventListener('mousemove', this.onMouseMove);

        this.exitZoomMode();
        this.tileManager.onMouseOut();
    };

    onMouseEnter = () => {
        this.container.addEventListener('mousemove', this.onMouseMove);
    };


    // ******** TOUCH EVENTS ************* //
    onTouchStart = _event => {
        const pos = this.getXYfromEvent(_event);
        const fixedY = this.viewportHeight / 2;

        this.tileManager.onPanStart(pos.x, fixedY);
    };

    onTouchMove = _event => {
        const pos = this.getXYfromEvent(_event);
        const fixedY = this.viewportHeight / 2;

        if (!this.hadInteraction) {
            this.hadInteraction = true;
            this.interactionCallback();
        }

        this.tileManager.onPan(pos.x, fixedY);
    };

    onTouchEnd = () => {
        this.tileManager.onPressEnd();
    };

    openDetailView = _event => {
        if (!this.zoomMode) {
            this.mouse.x = _event.layerX / this.viewport.x;
            this.mouse.y = _event.layerY / this.viewport.y;

            const active = this.tileManager.onClick(this.mouse.x, this.mouse.y);

            this.clickCallback(active);
        }
    };

    onContextMenu = _event => {
        _event.preventDefault();
    };

    // ******************* PUBLIC METHODS ******************* //
    goToPerson(_index, _direction) {
        this.tileManager.enterCloseup(_index, _direction);

        // Only show the interactive detail picture on click devices
        if (this.deviceType === 'click' && isMinScreenSize('desktop')) {
            this.peopleLoader.loadDetail(_index).then(img => {
                this.keyPhoto.show(img);
            });
        }
    }

    hidePerson() {
        this.tileManager.exitCloseup();
        this.keyPhoto.hide();
    }

    // ******************* EVENT LISTENERS ******************* //
    onWindowResize = () => {
        this.deviceType = isTouchDevice() ? 'touch' : 'click';

        this.viewport.set(window.innerWidth, this.viewportHeight);
        this.renderer.setSize(this.viewport.x, this.viewport.y);

        this.keyPhoto.onWindowResize();

        this.tileManager.updateCam();
        this.fpsCap = false;
    };

    // Updates once per frame
    update = _time => {
        if (this.rendering && !this.fpsCap) {
            this.clock.update(_time);
            this.render();
        }

        this.fpsCap = !this.fpsCap;
        this.updateRAF = requestAnimationFrame(this.update);
    };

    render() {
        // Render bgnd
        this.renderer.setViewport(0, 0, this.viewport.x, this.viewport.y);

        this.tileManager.update(this.clock.nowTime);

        // Render keyPhoto if profileView is on
        if (this.keyPhoto.visible) {
            this.rippleTex = this.rippleGen.update();
            this.keyPhoto.update(this.rippleTex);
            this.mouse.set(-1, -1);
        }
    }
}
