import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Burger.scss';

const Burger = ({ isActive, onClick }) => {
    return (
        <button
            className={cx(styles.burger, isActive && styles.active)}
            onClick={onClick}
        >
            <span />
            <span />
        </button>
    );
};

Burger.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Burger;
