import { createContext } from 'react';

export const controller = {
    showFeature: null,
    exitFeature: null,
    onCTAMouseOver: null,
    onCTAMouseOut: null,
    onWorkScroll: null,
    showWorkImage: null,
    onMouseOver: null,
    onMouseOut: null,
    onChargeBuild: null,
    onChargeRelease: null,
    transitionTo: null,
    pauseRendering: null,
    resumeRendering: null,
    fadeTo: null,
};

export default createContext(controller);
