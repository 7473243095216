import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';

import styles from './DefinitionList.scss';

const DefinitionList = ({ className, children }) => {
    return <dl className={cx(styles.definitionList, className)}>{children}</dl>;
};
DefinitionList.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export const DT = React.forwardRef((props, ref) => {
    const { as, theme, className, children, ...otherProps } = props;
    const classNames = cx(styles.dt, className);
    return (
        <Text
            as={as}
            theme={theme}
            className={classNames}
            ref={ref}
            {...otherProps}
        >
            {children}
        </Text>
    );
});
DT.propTypes = {
    as: PropTypes.string,
    theme: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};
DT.defaultProps = {
    as: 'dt',
    theme: 'definitionLists',
};

export const DD = React.forwardRef((props, ref) => {
    const { as, theme, className, children, ...otherProps } = props;
    const classNames = cx(styles.dd, className);
    return (
        <Text
            as={as}
            theme={theme}
            className={classNames}
            ref={ref}
            {...otherProps}
        >
            {children}
        </Text>
    );
});
DD.propTypes = {
    as: PropTypes.string,
    theme: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};
DD.defaultProps = {
    as: 'dd',
    theme: 'definitionLists',
};

export default DefinitionList;
