import React from 'react';
import PropTypes from 'prop-types';
import { TweenMax, Power1, Power3 } from 'gsap';

import FeaturedLink from 'components/ui/FeaturedLink';
import DefinitionList, { DT, DD } from 'components/ui/DefinitionList';

import styles from './FeaturedProject.scss';

class FeaturedProject extends React.Component {
    static propTypes = {
        project: PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        }),
        onMouseOver: PropTypes.func,
        onMouseOut: PropTypes.func,
        onClick: PropTypes.func,
    };

    titleRef = React.createRef();
    linkRef = React.createRef();

    componentDidMount() {
        TweenMax.staggerFrom(
            [this.titleRef.current, this.linkRef.current],
            1,
            { y: 30, ease: Power3.easeOut, delay: 0.9 },
            0.1,
            this.staggerDone
        );
        TweenMax.staggerTo(
            [this.titleRef.current, this.linkRef.current],
            0.5,
            { opacity: 1, ease: Power1.easeIn, delay: 0.9 },
            0.1
        );
    }

    render() {
        const { project, onMouseOver, onMouseOut, onClick } = this.props;
        if (!project) {
            return null;
        }
        return (
            <footer className={styles.featuredProject}>
                <DefinitionList>
                    <DT
                        theme="descriptor"
                        className={styles.dt}
                        ref={this.titleRef}
                    >
                        New Project
                    </DT>
                    <DD
                        onMouseEnter={onMouseOver}
                        onMouseLeave={onMouseOut}
                        className={styles.dd}
                        ref={this.linkRef}
                    >
                        <FeaturedLink
                            href={`/work/${project.slug}`}
                            onClick={onClick}
                        >
                            {project.name}
                        </FeaturedLink>
                    </DD>
                </DefinitionList>
            </footer>
        );
    }
}

export default FeaturedProject;
