import './styles/normalize.scss';
import './styles/theme.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { disableBodyScroll } from 'body-scroll-lock';
import { createThrottledEvent } from 'utils';

import StickyHeader from 'components/ui/StickyHeader';

import styles from './AppLayout.scss';

const ScrollContext = React.createContext();

class AppLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        headerView: PropTypes.string,
    };

    state = {
        scrollY: 0,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.children !== this.props.children) {
            this.setState({ scrollY: 0 });
        }
    }

    setScrollY = scrollY => {
        this.setState({ scrollY });
    };

    render() {
        const { headerView } = this.props;
        const { scrollY } = this.state;

        return (
            <ScrollContext.Provider value={this.setScrollY}>
                <div className={styles.appLayout}>
                    <StickyHeader view={headerView} scrollY={scrollY} />
                    {this.props.children}
                </div>
            </ScrollContext.Provider>
        );
    }
}

function mapStateToProps(state) {
    return {
        headerView: state.header.view,
    };
}

export default connect(mapStateToProps)(AppLayout);

export class ScrollContainer extends React.Component {
    static contextType = ScrollContext;

    static propTypes = {
        onScroll: PropTypes.func,
        className: PropTypes.string,
        children: PropTypes.node,
    };

    ref = React.createRef();

    componentDidMount() {
        const elem = this.ref.current;
        disableBodyScroll(elem);

        createThrottledEvent('resize', 'optimizedResize', this.ref.current);
        createThrottledEvent('scroll', 'optimizedScroll', this.ref.current);

        this.toggleScrollListeners('add');
    }

    componentWillUnmount() {
        this.toggleScrollListeners('remove');
    }

    toggleScrollListeners(method) {
        this.ref.current[`${method}EventListener`](
            'optimizedResize',
            this.setScrollState,
            false
        );
        this.ref.current[`${method}EventListener`](
            'optimizedScroll',
            this.setScrollState,
            { passive: true }
        );
    }

    setScrollState = () => {
        this.context(this.ref.current.scrollTop);
        if (this.props.onScroll) {
            this.props.onScroll(this.ref.current);
        }
    };

    render() {
        const { className, children } = this.props;
        return (
            <div className={className} ref={this.ref}>
                {children}
            </div>
        );
    }
}
