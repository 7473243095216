import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';

import grid from 'styles/grid.scss';
import styles from './TextModule.scss';

const TextModule = ({ className, keyword, headline, bodyCopy }) => {
    const hasKeyword = keyword != null;
    const subhead = !hasKeyword ? null : (
        <Text as="p" theme="eyebrows" className={styles.keyword}>
            {keyword}
        </Text>
    );
    const headerClass = hasKeyword ? styles.headerWithKeyword : styles.header;
    const header =
        headline == null ? null : (
            <header className={cx(grid.container, headerClass)}>
                {subhead}
                <Text as="h3" theme="callout" className={styles.headline}>
                    {headline}
                </Text>
            </header>
        );
    return (
        <div className={cx(styles.textModule, className)}>
            {header}
            {bodyCopy && (
                <div className={cx(grid.container, styles.body)}>
                    <Text as="p" theme="bodyCopy" className={styles.bodyCopy}>
                        {bodyCopy}
                    </Text>
                </div>
            )}
        </div>
    );
};

TextModule.propTypes = {
    className: PropTypes.string,
    keyword: PropTypes.string,
    headline: PropTypes.string,
    bodyCopy: PropTypes.string,
};

export default TextModule;
