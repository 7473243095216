import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { headerStatuses, headerViews, workViews } from 'utils/constants';
import { fetchPages, fetchWorkDetailPage } from 'services/Contentful';

const defaultState = {
    pages: {},
    workDetailPages: {},
    header: { status: headerStatuses.default, view: headerViews.default },
    workPage: { view: workViews.carousel },
    activeProjectIndex: 0,
};

export const RECEIVE_PAGE = 'RECEIVE_PAGE';
export const RECEIVE_PAGES = 'RECEIVE_PAGES';
export const RECEIVE_WORK_DETAIL_PAGE = 'RECEIVE_WORK_DETAIL_PAGE';
export const UPDATE_HEADER_STATUS = 'UPDATE_HEADER_STATUS';
export const UPDATE_WORK_PAGE_VIEW = 'UPDATE_WORK_PAGE_VIEW';
export const SET_ACTIVE_PROJECT_INDEX = 'SET_ACTIVE_PROJECT_INDEX';

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case RECEIVE_PAGE:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.path]: action.page,
                },
            };
        case RECEIVE_PAGES:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    ...action.pages,
                },
            };
        case RECEIVE_WORK_DETAIL_PAGE:
            return {
                ...state,
                workDetailPages: {
                    ...state.workDetailPages,
                    [action.slug]: action.workDetailPage,
                },
            };
        case UPDATE_HEADER_STATUS:
            return {
                ...state,
                header: {
                    ...state.header,
                    status: headerStatuses[action.headerStatus],
                },
            };
        case UPDATE_WORK_PAGE_VIEW:
            return {
                ...state,
                workPage: {
                    view: workViews[action.workView],
                },
            };
        case SET_ACTIVE_PROJECT_INDEX:
            return {
                ...state,
                activeProjectIndex: action.projectIndex,
            };
        default:
            return state;
    }
};

function shouldFetchWorkDetailPage(state, slug) {
    return !state.workDetailPages[slug];
}

function receivePages(pages) {
    return {
        type: RECEIVE_PAGES,
        pages,
    };
}

function receiveWorkDetailPage(slug, workDetailPage) {
    return {
        type: RECEIVE_WORK_DETAIL_PAGE,
        slug,
        workDetailPage,
    };
}

export function updateHeaderStatus(status) {
    return {
        type: UPDATE_HEADER_STATUS,
        headerStatus: status,
    };
}

export function updateWorkPageView(view) {
    return {
        type: UPDATE_WORK_PAGE_VIEW,
        workView: view,
    };
}

export function setActiveProjectIndex(projectIndex) {
    return {
        type: SET_ACTIVE_PROJECT_INDEX,
        projectIndex,
    };
}

function fetchPagesThunk(paths) {
    return dispatch => {
        return fetchPages(paths).then(results =>
            dispatch(
                receivePages(
                    results.reduce((o, result) => {
                        o[result.path] = result.content;
                        return o;
                    }, {})
                )
            )
        );
    };
}

export function fetchAllPages() {
    const paths = ['/', '/work', '/studio', '/contact'];
    return dispatch => dispatch(fetchPagesThunk(paths));
}

function fetchWorkDetailPageThunk(slug) {
    return dispatch => {
        return fetchWorkDetailPage(slug).then(results =>
            dispatch(receiveWorkDetailPage(slug, results))
        );
    };
}

export function fetchWorkDetailPageIfNeeded(slug) {
    return (dispatch, getState) => {
        if (shouldFetchWorkDetailPage(getState(), slug)) {
            return dispatch(fetchWorkDetailPageThunk(slug));
        } else {
            return Promise.resolve();
        }
    };
}

const composeEnhancers =
    typeof window === 'undefined'
        ? compose
        : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * @param {object} initialState
 * @param {boolean} options.isServer indicates whether it is a server side or client side
 * @param {Request} options.req NodeJS Request object (not set when client applies initialState from server)
 * @param {Request} options.res NodeJS Request object (not set when client applies initialState from server)
 * @param {boolean} options.debug User-defined debug mode param
 * @param {string} options.storeKey This key will be used to preserve store in global namespace for safe HMR
 */
export function makeStore(initialState /*, options*/) {
    return createStore(
        reducer,
        initialState,
        composeEnhancers(applyMiddleware(thunkMiddleware))
    );
}
