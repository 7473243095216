import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'routes';

import Text from 'components/ui/Text';

import styles from './FancyLink.scss';

const FancyLink = ({
    onClick,
    href,
    className,
    children,
    selected,
    ...props
}) => {
    const classNames = cx(styles.fancyLink, className, {
        [styles.selected]: selected === true,
    });
    const handleClick = e => {
        if (onClick) {
            e.preventDefault();
            onClick();
        }
    };
    if (href == null) {
        return (
            <Text
                as="span"
                role="button"
                theme="eyebrows"
                className={classNames}
                onClick={handleClick}
            >
                {children}
            </Text>
        );
    }
    return (
        <Link passHref route={href} {...props}>
            <Text as="a" theme="eyebrows" className={classNames}>
                {children}
            </Text>
        </Link>
    );
};
FancyLink.propTypes = {
    onClick: PropTypes.func,
    href: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    selected: PropTypes.bool,
};

FancyLink.defaultProps = {
    selected: false,
};

export default FancyLink;
