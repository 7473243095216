import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { modulo, getShortestDistance } from 'utils';
import { TweenMax, Linear } from 'gsap';
import Draggable from 'gsap/Draggable';
import ThrowPropsPlugin from 'utils/gsap/ThrowPropsPlugin';
import ModifiersPlugin from 'utils/gsap/ModifiersPlugin';

import KaleidoscopeContext from 'components/ui/Kaleidoscope/Context';
import Wheeler from 'components/ui/Wheeler';
import ProjectItem from './ProjectItem';

import styles from './ProjectCarousel.scss';

// prevent tree shaking
const throwPropsPlugin = ThrowPropsPlugin; // eslint-disable-line
const modifiersPlugin = ModifiersPlugin; // eslint-disable-line

class ProjectCarousel extends React.Component {
    static propTypes = {
        projects: PropTypes.array.isRequired,
        activeProjectIndex: PropTypes.number,
        disableVerticalScroll: PropTypes.bool,
        transitionState: PropTypes.string,
        hasTransition: PropTypes.bool,
        fontsLoaded: PropTypes.bool,
        className: PropTypes.string,
        onSnap: PropTypes.func,
    };

    static defaultProps = {
        activeProjectIndex: 0,
        disableVerticalScroll: false,
        hasTransition: true,
    };

    static contextType = KaleidoscopeContext;

    state = {
        hasActiveItem: true,
        activeProjectIndex: this.props.activeProjectIndex,
    };

    wheelTracker = {
        active: false,
        x: 0,
    };
    keyboardTracker = {
        sourceIndex: 0,
        targetIndex: 0,
    };

    wrapperRef = React.createRef();
    listRef = React.createRef();

    componentDidMount() {
        if (this.props.fontsLoaded) {
            this.initializeCarousel();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.fontsLoaded && this.props.fontsLoaded) {
            this.initializeCarousel();

            if (
                prevProps.transitionState === 'entered' &&
                this.context.showWorkImage
            ) {
                // Set new kaleidoscope texture
                const project = this.props.projects[
                    this.state.activeProjectIndex
                ];
                this.context.showWorkImage(project.image.file.url);
            }
        }
    }

    componentWillUnmount() {
        this.killCarousel();
    }

    initializeCarousel = () => {
        this.draggableDiv = document.createElement('div');

        window.addEventListener('resize', this.handleResize);
        window.addEventListener('keydown', this.handleKeydown);

        // Calculate each item's size
        const elements = Array.from(this.listRef.current.children);
        this.items = elements.map(el => {
            const rect = el.getBoundingClientRect();
            return {
                el,
                width: rect.width,
                height: rect.height,
            };
        });

        // Calculate total width of carousel and max size of items
        this.sizes = this.items.reduce(
            (acc, item) => ({
                totalWidth: acc.totalWidth + item.width,
                maxItemWidth: Math.max(acc.maxItemWidth, item.width),
                maxItemHeight: Math.max(acc.maxItemHeight, item.height),
            }),
            { totalWidth: 0, maxItemWidth: 0, maxItemHeight: 0 }
        );
        const { totalWidth, maxItemWidth, maxItemHeight } = this.sizes;

        // Calculate x-coords to center items on screen
        let widths = 0;
        this.items.forEach((item, i, items) => {
            item.xCenter =
                maxItemWidth +
                (window.innerWidth - items[i].width) / 2 -
                widths;
            widths += items[i].width;
        });

        // Set size and position of <ul> container, and show it
        TweenMax.set(this.listRef.current, {
            x: -maxItemWidth,
            height: maxItemHeight,
            yPercent: -50,
            visibility: 'visible',
        });

        // This infinitely repeating animation doesn't do anything by itself.
        // It is driven by `updateProgress()` which calls `progress()` on this.
        // `updateProgress()` itself is driven by Draggable.
        // The actual positioning of the carousel items happens in the
        // modifier, `xModifier`.
        this.animation = TweenMax.to(this.items, 5, {
            x: '+=' + totalWidth,
            ease: Linear.easeNone,
            paused: true,
            repeat: -1,
            modifiers: {
                x: this.xModifier,
            },
        });

        // This Draggable instance is responsible for drag and throw.
        // It drives the animation by calling `progress()` via its
        // `updateProgress()` callback.
        this.draggable = new Draggable(this.draggableDiv, {
            type: 'x',
            throwProps: true,
            snap: this.snap,
            trigger: this.wrapperRef.current,
            onPress: this.handleDraggablePress,
            onClick: this.handleDraggableClick,
            onDragStart: this.handleDraggableDragStart,
            onDrag: this.handleDraggableDrag,
            onThrowUpdate: this.handleDraggableThrowUpdate,
            onThrowComplete: this.handleDraggableThrowComplete,
            allowEventDefault: true,
            allowContextMenu: true,
            dragClickables: true,
        });

        // Move items to their initial positions
        this.initialProjectIndex = this.state.activeProjectIndex;
        let x = this.items[this.state.activeProjectIndex].xCenter;
        this.items.forEach(item => {
            item.x = this.xModifier(x, item);
            x += item.width;
        });

        ThrowPropsPlugin.track(this.wheelTracker, 'x');
    };

    killCarousel = () => {
        window.removeEventListener('resize', this.handleResize);
        this.animation && this.animation.kill();
        this.draggable && this.draggable.kill();
        this.wheelThrow && this.wheelThrow.kill();
        this.navigateTween && this.navigateTween.kill();
        ThrowPropsPlugin.untrack(this.wheelTracker);
    };

    handleResize = () => {
        this.killCarousel();
        this.initializeCarousel();
    };

    handleKeydown = event => {
        switch (event.keyCode) {
            case 37: // left
                this.navigate(-1);
                break;
            case 39: // right
                this.navigate(1);
                break;
        }
    };

    xModifier = (x, item) => {
        // This does the actual positioning of the items.
        // Called by Draggable for each item.
        const { totalWidth, maxItemWidth } = this.sizes;
        x = modulo(x, totalWidth);
        const inView = x - item.width <= window.innerWidth + maxItemWidth;
        const visibility = inView ? 'visible' : 'hidden';
        TweenMax.set(item.el, { x, visibility });
        return x;
    };

    snap = endValue => {
        // An endValue of 0 maps to the xCenter of the initial element
        const origin = this.items[this.initialProjectIndex].xCenter;
        const endValueAnim = endValue + origin;
        // if endValueAnim somewhere between left+leftWidth/2 and right-rightWidth/2
        //   -> find nearest
        // if endValueAnim to the left (bigger than all):
        //   -> subtract totalWidth until first condition is true
        // if endValueAnim to the right (smaller than all):
        //   -> add totalWidth until first condition is true
        const leftItem = this.items[0];
        const rightItem = this.items[this.items.length - 1];
        const left = leftItem.xCenter + leftItem.width / 2;
        const right = rightItem.xCenter - rightItem.width / 2;
        let win = 0;
        if (endValueAnim > left) {
            while (endValueAnim + win > left) {
                win -= this.sizes.totalWidth;
            }
        } else if (endValueAnim < right) {
            while (endValueAnim + win < right) {
                win += this.sizes.totalWidth;
            }
        }
        const endValueAnimClamped = endValueAnim + win;
        const nearestIndex = this.items.findIndex(
            item =>
                item.xCenter - item.width / 2 < endValueAnimClamped &&
                item.xCenter + item.width / 2 > endValueAnimClamped
        );
        // Activate targeted item
        this.setState({
            hasActiveItem: true,
            activeProjectIndex: nearestIndex,
        });
        // Set new kaleidoscope texture
        const project = this.props.projects[nearestIndex];
        this.context.showWorkImage(project.image.file.url);
        // Returned snapped end value
        const nearestItem = this.items[nearestIndex];
        return nearestItem.xCenter - win - origin;
    };

    handleDraggablePress = () => {
        // Preparing for a drag operation: set start values
        this.initProgress();
        // Pause potential wheel throws
        if (this.wheelThrow && this.wheelThrow.isActive()) {
            this.wheelThrow.pause();
        }
    };

    handleDraggableClick = () => {
        // We expected a drag operation but in the end it was just a click
        // Resume wheel throw if there was one
        if (
            this.wheelThrow &&
            this.wheelThrow.paused() &&
            this.wheelThrow.isActive()
        ) {
            this.wheelThrow.resume();
        }
    };

    handleDraggableDragStart = () => {
        // Drag operation starts
        // Disable centered item's active state
        this.setState({
            hasActiveItem: false,
        });
        // Kill wheel throws if there was one
        if (this.wheelThrow) {
            this.wheelThrow.kill();
        }
    };

    handleDraggableDrag = () => {
        this.updateProgress();
    };

    handleDraggableThrowUpdate = () => {
        this.updateProgress();
    };

    handleDraggableThrowComplete = () => {
        this.props.onSnap && this.props.onSnap(this.state.activeProjectIndex);
    };

    handleWheel = event => {
        // Ignore vertical scroll
        const touch = 'isEnd' in event;
        const yRatio = this.props.disableVerticalScroll && !touch ? 0 : 1;

        this.wheelThrow && this.wheelThrow.kill();
        if (!this.wheelTracker.active) {
            this.wheelTracker.active = true;
            this.initProgress();
        }

        this.wheelTracker.x -= event.deltaX + event.deltaY * yRatio;
        this.wheelUpdate();
    };

    handleWheelInertia = () => {
        const vars = {
            throwProps: { x: { end: this.snap } },
            onUpdate: this.wheelUpdate,
            onComplete: this.wheelComplete,
        };
        this.wheelThrow = ThrowPropsPlugin.to(this.wheelTracker, vars, 1, 0.5);
        this.wheelTracker.active = false;
    };

    wheelUpdate = () => {
        TweenMax.set(this.draggableDiv, { x: this.wheelTracker.x });
        this.draggable.update();
        this.updateProgress();
    };

    wheelComplete = () => {
        this.props.onSnap && this.props.onSnap(this.state.activeProjectIndex);
    };

    navigate(dir) {
        const { projects } = this.props;
        const { activeProjectIndex } = this.state;
        if (this.navigateTween && this.navigateTween.isActive()) {
            this.keyboardTracker.targetIndex += dir;
        } else {
            this.keyboardTracker.sourceIndex = activeProjectIndex;
            this.keyboardTracker.targetIndex = activeProjectIndex + dir;
            this.initProgress();
            // Deactivate current item
            this.setState({
                hasActiveItem: false,
            });
        }
        const { sourceIndex, targetIndex } = this.keyboardTracker;
        const incr = targetIndex - sourceIndex > 0 ? 1 : -1;
        const delta = Math.abs(targetIndex - sourceIndex);
        let dist = 0;
        for (let i = 0, j = sourceIndex; i <= delta; i++, j += incr) {
            const item = this.items[modulo(j, projects.length)];
            const mult = j === sourceIndex || j === targetIndex ? 0.5 : 1;
            dist += item.width * mult * incr;
        }
        // Tween targeted item into view
        const targetIndexClamped = modulo(targetIndex, projects.length);

        // Activate targeted item
        this.setState({
            hasActiveItem: true,
            activeProjectIndex: targetIndexClamped,
        });

        this.navigateTween = TweenMax.to(this.draggableDiv, 0.5, {
            x: this.xMin - dist,
            onUpdate: () => {
                this.draggable.update();
                this.updateProgress();
            },
            onComplete: () => {
                // Set new kaleidoscope texture
                const project = this.props.projects[targetIndexClamped];
                this.context.showWorkImage(project.image.file.url);
                this.props.onSnap && this.props.onSnap(targetIndexClamped);
            },
        });
    }

    navigateToIndex(targetIndex) {
        const { projects } = this.props;
        const { activeProjectIndex } = this.state;
        const activeIndex = activeProjectIndex;

        if (!(this.navigateTween && this.navigateTween.isActive())) {
            this.initProgress();
            // Deactivate current item
            this.setState({
                hasActiveItem: false,
            });
        }

        const dir = getShortestDistance(activeIndex, targetIndex, projects);
        const delta = Math.abs(dir);
        let dist = 0;
        // Calculate distance
        for (let i = 0, j = activeIndex; i <= delta; i++, j += dir) {
            const item = this.items[modulo(j, projects.length)];
            dist += item.width * 0.5 * dir;
        }

        // Activate targeted item
        this.setState({
            hasActiveItem: true,
            activeProjectIndex: targetIndex,
        });

        this.navigateTween = TweenMax.to(this.draggableDiv, 0.5, {
            x: this.xMin - dist,
            onUpdate: () => {
                this.draggable.update();
                this.updateProgress();
            },
            onComplete: () => {
                // Set new kaleidoscope texture
                const project = this.props.projects[targetIndex];
                this.context.showWorkImage(project.image.file.url);
                this.props.onSnap && this.props.onSnap(targetIndex);
            },
        });
    }

    initProgress() {
        // Initialize drag- and wheel-throws
        const { totalWidth } = this.sizes;
        this.xMin = this.draggable.x;
        this.xMax = this.xMin + totalWidth;
        this.progress = this.animation.progress();
        this.wheelTracker.x = this.draggable.x;
    }

    updateProgress() {
        // This drives the animation.
        const delta = this.normalize(this.draggable.x);
        this.animation.progress(this.progress + delta);
        this.context.onWorkScroll(delta);
    }

    normalize(x) {
        return (x - this.xMin) / (this.xMax - this.xMin);
    }

    render() {
        const {
            projects,
            className,
            transitionState,
            hasTransition,
        } = this.props;

        const { hasActiveItem, activeProjectIndex } = this.state;

        const wrapperClass = cx(styles.projectCarouselWrapper, className, {
            [styles.projectCarouselWrapperExited]: transitionState === 'exited',
            [styles.projectCarouselWrapperExiting]:
                transitionState === 'exiting',
            [styles.projectCarouselWrapperEntering]:
                transitionState === 'entering',
        });

        const transitionClass = cx(styles.projectCarouselTransitionContainer, {
            [styles.projectCarouselTransition]: hasTransition,
        });

        return (
            <Wheeler
                onWheel={this.handleWheel}
                onInertia={this.handleWheelInertia}
            >
                <div ref={this.wrapperRef} className={wrapperClass}>
                    <div className={transitionClass}>
                        <ul
                            ref={this.listRef}
                            className={styles.projectCarousel}
                        >
                            {projects.map((project, i) => (
                                <li key={i} className={styles.projectItem}>
                                    <ProjectItem
                                        as={null}
                                        project={project}
                                        activate={() => {
                                            this.navigateToIndex(i);
                                        }}
                                        active={
                                            hasActiveItem &&
                                            activeProjectIndex === i
                                        }
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Wheeler>
        );
    }
}
export default ProjectCarousel;
