import React from 'react';
import { Link } from 'routes';

import Logo from 'components/ui/Logo';

import styles from './Logo.scss';

const HeaderLogo = () => {
    return (
        <Link route="/">
            <a className={styles.logo}>
                <Logo className={styles.symbol} />
            </a>
        </Link>
    );
};

export default HeaderLogo;
