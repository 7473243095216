import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CarouselModule from './modules/CarouselModule';
import InlineVideo from './modules/InlineVideoModule';
import TextModule from './modules/TextModule';
import VimeoModule from './modules/VimeoModule';
import WaveCover from 'components/ui/WaveCover';

import styles from './Modules.scss';

const Modules = ({ className, items, gradient }) => {
    return (
        <section className={cx(styles.modules, className)}>
            <WaveCover orientation="up" />
            {items.map((item, i) => {
                const { contentTypeId, ...props } = item;
                switch (contentTypeId) {
                    case 'moduleText':
                        return <TextModule key={i} {...props} />;
                    case 'moduleCarousel':
                        return <CarouselModule key={i} {...props} />;
                    case 'moduleVimeo':
                        return (
                            <VimeoModule
                                key={i}
                                {...props}
                                gradient={gradient}
                            />
                        );
                    case 'moduleInlineVideo':
                        return <InlineVideo key={i} {...props} />;
                }
            })}
            <WaveCover orientation="down" className={styles.lastWave} />
        </section>
    );
};

Modules.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    gradient: PropTypes.array,
};

export default Modules;
