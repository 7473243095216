/*
 * GridTexture.js
 * ===========
 * Draws a grid of photos based on its index.
 * Old mobile devices have a max texture size of 4096
 * Each photo is in a 512 * 512 cell
 * for a max of 8 * 8 images in the grid.
 */

import * as THREE from 'three';

export default function GridTexture() {
    // Dimensions
    // iPhone 5 max texture size: 4096 x 4096
    this.imgSize = new THREE.Vector2(512, 512);
    this.cols = 8;
    this.rows = 8;

    // Canvas
    this.canvas = document.createElement('canvas');
    this.canvas.width = this.canvas.height = this.imgSize.x * this.cols;

    this.ctx = this.canvas.getContext('2d');

    // Texture
    this.texture = new THREE.Texture(this.canvas, { format: THREE.RGBFormat });

    // Fetches image, draws it on corresponding grid
    this.drawImageByIndex = function(_url, _index) {
        const {
            cols,
            rows,
            imgSize: { x: sizeX, y: sizeY },
        } = this;

        const imgElem = new Image();
        imgElem.crossOrigin = 'anonymous';
        imgElem.src = _url;

        // Draws left-right, bottom-up to match UV convention
        const x = sizeX * (_index % cols);
        const y = sizeY * (rows - 1 - Math.floor(_index / cols));

        const imgPromise = new Promise(resolve => {
            const handleSuccess = () => {
                this.ctx.clearRect(x, y, sizeX, sizeY);
                this.ctx.drawImage(imgElem, x, y, sizeX, sizeY);

                resolve(_index);
            };

            const handleError = () => {
                this.ctx.fillStyle = 'white';
                this.ctx.fillRect(x, y, sizeX, sizeY);
                resolve(_index);
            };

            if (
                imgElem.complete &&
                imgElem.naturalWidth &&
                imgElem.naturalHeight
            ) {
                handleSuccess();
            } else {
                imgElem.addEventListener('load', handleSuccess, false);
                imgElem.addEventListener('error', handleError, false);
            }
        });

        return { imgPromise, imgElem };
    };

    this.updateTexture = function() {
        this.texture.needsUpdate = true;
    };

    this.convert = () => {
        // Get a canvas snapshot
        const dataURL = this.canvas.toDataURL();

        // Load it as a new texture
        this.texture = new THREE.TextureLoader().load(dataURL, () => {
            // update with new texture
            this.updateTexture();

            // free the f*** memory space
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.canvas.width = this.canvas.height = 1;

            delete this.ctx;
            delete this.canvas;
        });
    };

    this.getTexture = () => this.texture;
    this.getColumns = () => this.cols;

    // Deallocate memory from GPU
    this.dispose = () => {
        this.texture.dispose();
    };
}
