import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Stats from './Stats';
import SvgIcon from 'components/ui/SvgIcon';

import styles from './Result.scss';

class Result extends React.Component {
    static propTypes = {
        isTouch: PropTypes.bool.isRequired,
        answers: PropTypes.array.isRequired,
        selectedAnswer: PropTypes.number.isRequired,
        onClickClose: PropTypes.func.isRequired,
        onChangeResultVideo: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.startAutoCloseTimeout();
    }

    componentWillUnmount() {
        this.cancelAutoCloseTimeout();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.selectedAnswer !== this.props.selectedAnswer ||
            prevProps.isTouch !== this.props.isTouch
        ) {
            this.startAutoCloseTimeout();
        }
    }

    handleCloseClick = () => {
        this.cancelAutoCloseTimeout();
        this.props.onClickClose();
    };

    startAutoCloseTimeout() {
        this.cancelAutoCloseTimeout();
        if (this.props.isTouch) {
            this.timeoutId = setTimeout(this.props.onClickClose, 5000);
        }
    }

    cancelAutoCloseTimeout() {
        clearTimeout(this.timeoutId);
    }

    renderStats() {
        const { answers, onChangeResultVideo } = this.props;
        return <Stats answers={answers} changeVideo={onChangeResultVideo} />;
    }

    renderTouch() {
        const { answers, selectedAnswer } = this.props;
        const { gif, title } = answers[selectedAnswer];
        return (
            <div className={cx(styles.result, styles.isTouch)}>
                <div className={styles.wrapper} onClick={this.handleCloseClick}>
                    <img src={gif.file.url} alt={title} />
                </div>
                {this.renderStats()}
            </div>
        );
    }

    renderMouse() {
        const { answers, selectedAnswer } = this.props;
        const ytSrc =
            'https://www.youtube-nocookie.com/embed/' +
            answers[selectedAnswer].youtubeVideoId +
            '?autoplay=1&controls=0&fs=0&modestbranding=1&playsinline=1&color=white&rel=0';
        return (
            <div className={styles.result}>
                <div className={styles.wrapper}>
                    <iframe
                        src={ytSrc}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
                <div className={styles.closeIconWrapper}>
                    <div
                        className={styles.closeIcon}
                        onClick={this.handleCloseClick}
                    >
                        <SvgIcon iconType="close" />
                    </div>
                </div>
                {this.renderStats()}
            </div>
        );
    }

    render() {
        return this.props.isTouch ? this.renderTouch() : this.renderMouse();
    }
}

export default Result;
