import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import useMediaQuery from 'hooks/useMediaQuery';

import breakpoints from 'styles/breakpoints.scss';
import styles from './Details.scss';

const Details = ({ data: { name, role, picture } }) => {
    if (typeof name === 'undefined') return null;

    const isDesktop = useMediaQuery(
        `(min-width: ${breakpoints.desktop})`,
        true
    );
    const completeName = isDesktop ? name.split(' ') : name;

    return (
        <div className={styles.root}>
            {!isDesktop && (
                <div className={styles.pictureWrapper}>
                    <img
                        className={styles.picture}
                        src={picture.file.url}
                        alt={picture.title}
                    />
                </div>
            )}
            <Text as="p" theme="callout">
                {isDesktop ? completeName[0] : completeName}
            </Text>
            {isDesktop && (
                <Text as="p" theme="callout">
                    {completeName[1]}
                </Text>
            )}
            <Text as="p" theme="bodyCopy">
                {role}
            </Text>
        </div>
    );
};

Details.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Details;
