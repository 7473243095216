import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Controls.scss';

const Controls = ({ onClickPrev, onClickGrid, onClickNext, visible }) => {
    const animClass = cx(styles.controls, {
        [styles.visible]: visible,
    });

    return (
        <nav className={animClass}>
            <div className={styles.controlsButton} onClick={onClickPrev}>
                <svg>
                    <path
                        className={styles.arrowLOn}
                        d="M21.5,25L16.5,20L21.5,15z"
                    />
                    <path
                        className={styles.arrowLOff}
                        d="M21.5,25L16.5,20L21.5,15z"
                    />
                </svg>
            </div>
            <div className={styles.controlsButton} onClick={onClickGrid}>
                <svg className={styles.grid}>
                    <path d="M15,15h2v2h-2z" />
                    <path d="M15,19h2v2h-2z" />
                    <path d="M15,23h2v2h-2z" />
                    <path d="M19,15h2v2h-2z" />
                    <path d="M19,19h2v2h-2z" />
                    <path d="M19,23h2v2h-2z" />
                    <path d="M23,15h2v2h-2z" />
                    <path d="M23,19h2v2h-2z" />
                    <path d="M23,23h2v2h-2z" />
                </svg>
            </div>
            <div className={styles.controlsButton} onClick={onClickNext}>
                <svg>
                    <path
                        className={styles.arrowROn}
                        d="M18.5,25L23.5,20L18.5,15z"
                    />
                    <path
                        className={styles.arrowROff}
                        d="M18.5,25L23.5,20L18.5,15z"
                    />
                </svg>
            </div>
        </nav>
    );
};

Controls.propTypes = {
    onClickPrev: PropTypes.func.isRequired,
    onClickGrid: PropTypes.func.isRequired,
    onClickNext: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
};

export default Controls;
