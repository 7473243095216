import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import VimeoPlayer from 'components/ui/VimeoPlayer';

import grid from 'styles/grid.scss';
import styles from './VimeoModule.scss';

class VimeoModule extends React.Component {
    render() {
        const {
            className,
            gradient: [c1],
            poster,
            id,
        } = this.props;

        return (
            <div className={cx(grid.container, styles.vimeoModule, className)}>
                <VimeoPlayer color={c1} poster={poster} videoId={id} />
            </div>
        );
    }
}

VimeoModule.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    poster: PropTypes.object,
    gradient: PropTypes.array,
};

export default VimeoModule;
