import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Url from 'url-parse';

import Text from 'components/ui/Text';
import DefinitionList, { DT, DD } from 'components/ui/DefinitionList';
import WaveCover from 'components/ui/WaveCover';

import grid from 'styles/grid.scss';
import styles from './Info.scss';

const LiveSite = ({ url, gradient }) => {
    const parsedUrl = new Url(url);
    const domain = parsedUrl.hostname.replace(/^www./, '');
    const bgImage = `linear-gradient(90deg, ${gradient[0]}, ${gradient[1]})`;

    return (
        <DefinitionList className={styles.topList}>
            <DT>Live Site</DT>
            <DD>
                <Text
                    as="a"
                    theme="eyebrows"
                    className={styles.siteLink}
                    href={url}
                    target="_blank"
                >
                    {domain}
                    <span
                        className={styles.underline}
                        style={{ backgroundImage: bgImage }}
                    />
                </Text>
            </DD>
        </DefinitionList>
    );
};

LiveSite.propTypes = {
    url: PropTypes.string,
    gradient: PropTypes.array,
};

const Info = ({ className, url, capabilities, description, gradient }) => {
    return (
        <section className={cx(styles.info, className)}>
            <WaveCover orientation="up" />
            <div className={cx(grid.container, styles.container)}>
                <div className={styles.listContainer}>
                    {url && <LiveSite url={url} gradient={gradient} />}
                    <DefinitionList
                        className={cx(
                            styles.bottomList,
                            !url && styles.singleInfo
                        )}
                    >
                        <DT>Capabilities</DT>
                        <DD>{capabilities}</DD>
                    </DefinitionList>
                </div>
                <Text as="p" theme="bodyCopy" className={styles.description}>
                    {description}
                </Text>
            </div>
            <WaveCover />
        </section>
    );
};

Info.propTypes = {
    className: PropTypes.string,
    capabilities: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string,
    gradient: PropTypes.array.isRequired,
};

export default Info;
