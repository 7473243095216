// Calculates elapsed time since last frame and other time utils
export default function Clock(_timeMultip) {
    this.delta = 0;
    this.frameCount = 0;
    this.nowTime = 0;
    this.prevTime = 0;
    this.seconds = 0;
    this.timeMultip = typeof _timeMultip === 'undefined' ? 1 : _timeMultip;
    this.totalTime = 0;

    this.update = function(_timeStamp) {
        const seconds = _timeStamp / 1000;
        const delta = seconds - this.prevTime;

        this.seconds = seconds;
        this.delta = delta >= 1 ? 0 : delta;
        this.nowTime += this.delta * this.timeMultip;
        this.totalTime += this.delta;
        this.frameCount++;
        this.prevTime = this.seconds;
    };
}
