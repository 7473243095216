import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TweenMax } from 'gsap';
import { isMinScreenSize } from 'utils';

import Draggable from 'gsap/Draggable';
import ThrowPropsPlugin from 'utils/gsap/ThrowPropsPlugin';

import ImageVideo from 'components/ui/ImageVideo';

import styles from './Culture.scss';

// prevent tree shaking
const plugin = ThrowPropsPlugin; // eslint-disable-line

class Culture extends React.Component {
    static propTypes = {
        content: PropTypes.array.isRequired,
    };

    // We can do this because the content prop is required and never changes.
    items = this.props.content.map(item => ({
        naturalWidth: item.image.file.details.image.width,
        naturalHeight: item.image.file.details.image.height,
        url: item.image.file.url,
        videos: item.videos,
        ref: React.createRef(),
        bounds: null,
        position: '',
        isVisible: false,
        visibilityChange: false,
    }));

    state = {
        itemsVisibility: this.props.content.map(({ isVisible }) => isVisible),
    };

    boundsRef = React.createRef();
    wrapperRef = React.createRef();

    componentDidMount() {
        this.initializeCarousel();
    }

    componentWillUnmount() {
        this.killCarousel();
    }

    initializeCarousel = () => {
        this.killCarousel();
        const bounds = this.boundsRef.current.getBoundingClientRect();
        const isMobile = !isMinScreenSize('tablet');

        let { scale } = this.items.reduce(
            (result, item) => {
                const { naturalWidth, naturalHeight } = item;
                const aspect = naturalWidth / naturalHeight;
                if (result.lowestAspect > aspect) {
                    return {
                        lowestAspect: aspect,
                        scale: bounds.height / naturalHeight,
                    };
                } else {
                    return result;
                }
            },
            { lowestAspect: Number.MAX_VALUE, scale: 1 }
        );

        scale = isMobile ? scale * 0.65 : scale;

        TweenMax.set(this.wrapperRef.current, { x: 0 });
        this.items.forEach(item => {
            item.bounds = null;
            item.position = '';
            item.isVisible = false;
            item.visibilityChange = false;
            TweenMax.set(item.ref.current, {
                width: item.naturalWidth * scale,
                height: item.naturalHeight * scale,
            });
        });
        this.draggable = new Draggable(this.wrapperRef.current, {
            type: 'x',
            throwProps: true,
            bounds: this.boundsRef.current,
            edgeResistance: 0.65,
            allowEventDefault: true,
            allowContextMenu: true,
            onDrag: this.handleDraggableDrag,
            onThrowUpdate: this.handleDraggableThrowUpdate,
        });
        window.addEventListener('resize', this.initializeCarousel);
        this.update(true);
    };

    killCarousel = () => {
        if (this.draggable) this.draggable.kill();
        window.removeEventListener('resize', this.initializeCarousel);
    };

    handleDraggableDrag = () => {
        this.update();
    };

    handleDraggableThrowUpdate = () => {
        this.update();
    };

    update(force) {
        let hasChanged = false;
        this.items.forEach(item => {
            if (!item.bounds) {
                item.bounds = item.ref.current.getBoundingClientRect();
            }
            const { isIntersecting, position } = this.testIntersection(
                -this.draggable.x,
                -this.draggable.x + window.innerWidth,
                item.bounds.x,
                item.bounds.x + item.bounds.width
            );
            item.visibilityChange = isIntersecting !== item.isVisible;
            item.isVisible = isIntersecting;
            if (!isIntersecting) {
                item.position = position;
            }
            if (item.visibilityChange) {
                hasChanged = true;
            }
        });
        if (hasChanged || force) {
            this.setState({
                itemsVisibility: this.items.map(({ isVisible }) => isVisible),
            });
        }
    }

    testIntersection(a1, a2, b1, b2) {
        const isIntersecting = a1 <= b2 && b1 <= a2;
        const position = isIntersecting ? '' : a1 > b2 ? 'left' : 'right';
        return { isIntersecting, position };
    }

    renderItems() {
        const { itemsVisibility } = this.state;
        return this.items.map((item, i) => {
            const isVisible = itemsVisibility[i];
            const className = cx(styles.itemWrapper, {
                [styles[item.position]]: !isVisible,
                [styles.visible]: isVisible,
            });
            return (
                <li className={className} ref={item.ref} key={`gif-${i}`}>
                    <ImageVideo
                        image={item.url}
                        videos={item.videos}
                        className={styles.item}
                        isVisible={isVisible}
                    />
                </li>
            );
        });
    }

    render() {
        return (
            <section ref={this.boundsRef} className={styles.gallery}>
                <ul ref={this.wrapperRef} className={styles.list}>
                    {this.renderItems()}
                </ul>
            </section>
        );
    }
}

Culture.propTypes = {
    content: PropTypes.array.isRequired,
};

export default Culture;
