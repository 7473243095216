import React from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import cx from 'classnames';
import { connect } from 'react-redux';

import { ScrollContainer } from 'components/ui/AppLayout';
import KaleidoscopeContext from 'components/ui/Kaleidoscope/Context';

import CTA from './CTA';
import Headline from './Headline';
import FeaturedProject from './FeaturedProject';

import grid from 'styles/grid.scss';
import styles from './Landing.scss';

class Landing extends React.Component {
    static contextType = KaleidoscopeContext;

    static getInitialProps = ({ store }) => {
        const state = store.getState();
        return {
            contentTypeId: state.pages['/'].contentTypeId,
        };
    };

    static propTypes = {
        // Global pageProps from App.js getInitialProps
        landing: PropTypes.shape({
            headline: PropTypes.string.isRequired,
            featuredProject: PropTypes.shape({
                name: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired,
                image: PropTypes.object.isRequired,
            }),
        }).isRequired,
        // Component-specific props from getInitialProps
        contentTypeId: PropTypes.string.isRequired,
        // Props passed directly from App.js render()
        fontsLoaded: PropTypes.bool.isRequired,
    };

    isExiting = false;

    componentWillUnmount() {
        document.documentElement.classList.remove('hideSystemCursor');
    }

    handleWorkMouseOver = () => {
        this.context.onCTAMouseOver();
    };

    handleWorkMouseOut = () => {
        this.context.onCTAMouseOut();
    };

    handleFeaturedMouseOver = () => {
        this.context.showFeature();
    };

    handleFeaturedMouseOut = () => {
        if (!this.isExiting) {
            this.context.exitFeature();
        }
    };

    handleFeaturedClick = event => {
        // When the user clicks on the "Featured Project" link, the
        // image has already been swapped, so we pass the additional
        // query param "swapimage=nope" for the Work Detail page to know
        // that it shouldn't load and swap that image again
        const href = event.target.getAttribute('href');
        const path = href.split('/');
        const slug = path.pop();
        // https://github.com/zeit/next.js/issues/1222
        Router.push(`/work-detail?slug=${slug}&swapimage=nope`, href);
        event.preventDefault();
        this.isExiting = true;
    };

    handleMainMouseOver = event => {
        if (event.button === 0 || event.touches) {
            const e =
                event.touches && event.touches.length
                    ? event.touches[0]
                    : event;
            this.context.onMouseOver({
                x: e.clientX,
                y: e.clientY,
            });
        }
    };

    handleMainMouseOut = () => {
        this.context.onMouseOut();
    };

    handleMainMouseDown = event => {
        if (event.button === 0 || event.touches) {
            const e =
                event.touches && event.touches.length
                    ? event.touches[0]
                    : event;
            this.context.onChargeBuild({
                x: e.clientX,
                y: e.clientY,
            });
        }
    };

    handleMainMouseUp = () => {
        this.context.onChargeRelease();
    };

    render() {
        const { fontsLoaded, landing } = this.props;
        const { headline, featuredProject } = landing;

        return (
            <ScrollContainer className={cx(styles.landing, grid.container)}>
                <div
                    className={styles.chargeHotspot}
                    onMouseDown={this.handleMainMouseDown}
                    onMouseUp={this.handleMainMouseUp}
                    onTouchStart={this.handleMainMouseDown}
                    onTouchEnd={this.handleMainMouseUp}
                    onMouseOver={this.handleMainMouseOver}
                    onMouseOut={this.handleMainMouseOut}
                />
                <Headline fontsLoaded={fontsLoaded}>{headline}</Headline>
                <CTA
                    label="View Work"
                    onMouseOver={this.handleWorkMouseOver}
                    onMouseOut={this.handleWorkMouseOut}
                />
                <FeaturedProject
                    project={featuredProject}
                    onMouseOver={this.handleFeaturedMouseOver}
                    onMouseOut={this.handleFeaturedMouseOut}
                    onClick={this.handleFeaturedClick}
                />
            </ScrollContainer>
        );
    }
}

export default connect()(Landing);
