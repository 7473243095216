import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { modulo } from 'utils';
import { Power2, TweenMax } from 'gsap';
import Draggable from 'gsap/Draggable';
import ThrowPropsPlugin from 'utils/gsap/ThrowPropsPlugin';

import ImageVideo from 'components/ui/ImageVideo';

import styles from './CarouselModule.scss';

// prevent tree shaking
const plugin = ThrowPropsPlugin; // eslint-disable-line

class CarouselModule extends React.Component {
    constructor() {
        super();
        this.state = {
            currentSlideIndex: 0,
            slideWidth: 0,
            slideHeight: 0,
            slideGap: 0,
            leftMargin: 0,
            isInView: false,
        };

        this.moduleRef = React.createRef();
        this.containerRef = React.createRef();
        this.isAnimating = false;
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(this.handleIntersection, {});
        this.observer.observe(this.moduleRef.current);

        this.draggable = new Draggable(this.containerRef.current, {
            type: 'x',
            throwProps: true,
            snap: this.handleThrowSnap,
            maxDuration: 0.25,
            edgeResistance: 0.25,
            allowContextMenu: true,
            onPress: this.handleDragPress,
            onThrowComplete: this.handleThrowComplete,
            onDragStart: this.handleDragStart,
            onClick: this.handleClick,
            zIndexBoost: false,
        });

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentDidUpdate(prevProps, prevState) {
        const { isInView } = this.state;
        if (prevState.isInView !== isInView) {
            if (!isInView) {
                clearInterval(this.idleInterval);
            }
            this.handleResize();
        }
    }

    componentWillUnmount() {
        this.tween && this.tween.kill();
        this.draggable && this.draggable.kill();
        this.observer && this.observer.disconnect();
        window.removeEventListener('resize', this.handleResize);
        clearInterval(this.idleInterval);
    }

    enableAutoAdvance() {
        clearInterval(this.idleInterval);
        this.idleInterval = setInterval(() => {
            this.nextSlide();
        }, 4000);
    }

    handleResize = () => {
        const rect = this.moduleRef.current.getBoundingClientRect();
        const width = rect.width;
        const { gridMargin, gridGap, gridColumns } = this.getGridValues();
        // Calculate the width of a grid cell
        // (Viewport width, minus left and right margins, minus all gaps,
        // divided by number of columns)
        const gridCellWidth =
            (width - 2 * gridMargin - (gridColumns - 1) * gridGap) /
            gridColumns;
        // Calculate the slide's left and right margins
        // (different for desktop resolutions and mobile/tablet)
        const isDesktop = gridColumns === 12;
        const leftMargin = isDesktop ? gridMargin : gridMargin / 2;
        const rightMargin = isDesktop
            ? gridMargin + gridGap + gridCellWidth
            : gridMargin;
        // Calculate the width of a slide
        const slideWidth = width - leftMargin - rightMargin;
        // Calculate the maximum height of the slides (Based on the slide
        // width and the aspect ratios of the images)
        const heights = this.props.items.map(item => {
            const size = item.image.file.details.image;
            return (slideWidth * size.height) / size.width;
        });
        const slideHeight = Math.ceil(Math.max(...heights));
        // Calculate the width of the gap between the slides
        const slideGap = isDesktop ? gridGap : gridMargin / 2;
        // Update Draggable bounds
        this.draggable.applyBounds({
            top: 0,
            // Do not ask me how i got to the following values.
            // They seem to work though.
            // 🤦🏼‍
            left: -(slideWidth + slideGap - leftMargin),
            width: 3 * slideWidth + 3 * leftMargin + rightMargin,
        });
        // Update state
        this.setState({
            slideWidth,
            slideHeight,
            slideGap,
            leftMargin,
        });
    };

    handleDragPress = () => {
        if (this.tween) {
            this.tween.kill();
            this.isAnimating = false;
        }
    };

    handleDragStart = () => {
        clearInterval(this.idleInterval);
    };

    handleThrowSnap = endValue => {
        const { slideWidth, slideGap } = this.state;
        const snapPoints = [-(slideWidth + slideGap), 0, slideWidth + slideGap];
        let smallesDist = Number.MAX_VALUE;
        let smallesIdx = 0;
        snapPoints.forEach((pt, i) => {
            const dist = Math.abs(pt - endValue);
            if (smallesDist > dist) {
                smallesDist = dist;
                smallesIdx = i;
            }
        });
        this.throwDirection = smallesIdx - 1;
        return snapPoints[smallesIdx];
    };

    handleThrowComplete = () => {
        const dir = this.throwDirection;
        if (dir === 1 || dir === -1) {
            this.setState(
                state => ({
                    currentSlideIndex: state.currentSlideIndex - dir,
                }),
                () => {
                    TweenMax.set(this.containerRef.current, {
                        x: 0,
                    });
                }
            );
        }
    };

    handleIntersection = ([entry]) => {
        this.setState(state => {
            if (state.isInView !== entry.isIntersecting) {
                return {
                    isInView: entry.isIntersecting,
                };
            }
            return null;
        });
    };

    handleClick = () => {
        if (!this.isAnimating) {
            this.nextSlide();
        }
    };

    nextSlide() {
        this.animate();
    }

    prevSlide() {
        this.animate(-1);
    }

    animate(dir = 1) {
        clearInterval(this.idleInterval);
        this.isAnimating = true;
        this.tween = TweenMax.to(this.containerRef.current, 0.75, {
            x: (this.state.slideWidth + this.state.slideGap + 1) * -dir,
            ease: Power2.easeInOut,
            onComplete: () => {
                this.setState(
                    state => ({
                        currentSlideIndex: state.currentSlideIndex + dir,
                    }),
                    () => {
                        this.isAnimating = false;
                        TweenMax.set(this.containerRef.current, {
                            x: 0,
                        });
                    }
                );
            },
        });
    }

    getGridValues() {
        const rootStyle = getComputedStyle(document.body);
        function getFloatVal(cssVar) {
            return parseFloat(rootStyle.getPropertyValue(cssVar).trim());
        }
        const gridMargin = getFloatVal('--grid-margin');
        const gridGap = getFloatVal('--grid-gap');
        const gridColumns = getFloatVal('--grid-columns');
        return { gridMargin, gridGap, gridColumns };
    }

    getSlides() {
        const items = this.props.items;
        const {
            currentSlideIndex,
            slideWidth,
            slideHeight,
            slideGap,
            isInView,
        } = this.state;
        return (
            <React.Fragment>
                {[-1, 0, 1, 2].map(i => {
                    const j = modulo(currentSlideIndex + i, items.length);
                    const item = items[j];
                    const key = item.image.file.url + (currentSlideIndex + i);
                    const isActiveSlide =
                        key.charAt(key.length - 1) == currentSlideIndex;
                    return (
                        <ImageVideo
                            key={key}
                            image={item.image.file.url}
                            videos={item.videos}
                            className={styles.videoSlide}
                            isVisible={isInView}
                            isActiveSlide={isActiveSlide}
                            style={{
                                width: slideWidth,
                                height: slideHeight,
                                left: i * (slideWidth + slideGap),
                                cursor: 'grab',
                            }}
                        />
                    );
                })}
            </React.Fragment>
        );
    }

    render() {
        const { className } = this.props;
        const { slideHeight, leftMargin } = this.state;

        const containerStyle = {
            left: leftMargin,
            height: slideHeight,
            cursor: 'grab',
        };

        return (
            <div
                ref={this.moduleRef}
                className={cx(styles.carouselModule, className)}
            >
                <div
                    ref={this.containerRef}
                    style={containerStyle}
                    className={styles.container}
                >
                    {this.getSlides()}
                </div>
            </div>
        );
    }
}

CarouselModule.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array.isRequired,
};

export default CarouselModule;
