import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'routes';

import useIntersection from 'hooks/useIntersection';

import KaleidoscopeContext from 'components/ui/Kaleidoscope/Context';
import Text from 'components/ui/Text';

import grid from 'styles/grid.scss';
import styles from './Footer.scss';

const Footer = ({ callToAction, tagline, link }) => {
    const ctx = useContext(KaleidoscopeContext);
    const footer = useRef();
    useIntersection(
        footer,
        isInView => {
            const { pauseRendering, resumeRendering } = ctx;
            if (pauseRendering && resumeRendering) {
                if (isInView) {
                    resumeRendering(0);
                } else {
                    pauseRendering(0);
                }
            }
        },
        [ctx]
    );
    return (
        <footer className={styles.footer} ref={footer}>
            <div className={cx(grid.container, styles.wrapper)}>
                <Text as="h5" theme="eyebrows" className={styles.tagline}>
                    {tagline}
                </Text>
                <Link href={link}>
                    <Text
                        as="a"
                        theme="largeLinksStudioFooter"
                        className={styles.callToAction}
                    >
                        {callToAction}
                    </Text>
                </Link>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    callToAction: PropTypes.string.isRequired,
    tagline: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default Footer;
