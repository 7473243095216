import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'routes';

import Text from 'components/ui/Text';
import KaleidoscopeContext from 'components/ui/Kaleidoscope/Context';

import styles from './ProjectItem.scss';

class ProjectItem extends React.Component {
    static contextType = KaleidoscopeContext;

    static propTypes = {
        as: PropTypes.string,
        project: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            client: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            image: PropTypes.object.isRequired,
        }).isRequired,
        active: PropTypes.bool,
        activate: PropTypes.func,
        className: PropTypes.string,
    };

    static defaultProps = {
        as: 'span',
        active: false,
        activate: () => {},
    };

    ref = React.createRef();

    render() {
        const { as, project, active, className, ...props } = this.props;
        const projectItemClass = cx(styles.projectItem, className, {
            [styles.projectItemActive]: active,
        });
        const subHeadline = (
            <React.Fragment>
                <span className={styles.client}>{project.client}</span>
                <span className={styles.separator}>{' / '}</span>
                <span className={styles.type}>{project.type}</span>
            </React.Fragment>
        );
        const content = (
            <Link passHref route={`/work/${project.slug}`}>
                <Text
                    as="a"
                    theme="largeLinksWorkList"
                    className={projectItemClass}
                    onClick={e => {
                        if (!active) {
                            e.preventDefault();
                            this.props.activate();
                        }
                    }}
                    ref={this.ref}
                >
                    {project.name}
                    <Text as="span" theme="eyebrows" className={styles.sub}>
                        <span className={styles.subInner}>{subHeadline}</span>
                    </Text>
                </Text>
            </Link>
        );
        if (as != null) {
            return React.createElement(as, props, content);
        }
        return content;
    }
}

export default ProjectItem;
