import React, { useEffect, useState } from 'react';

import styles from './Awards.scss';

const Logo = ({ id, url, width, height }) => {
    const [animationStyles, setAnimationStyles] = useState({});

    const gradientDelay = `${Math.random() * 2}s`;
    const gradientDuration = `${2 + Math.random() * 4}s`;
    const gradientDirection =
        Math.random() < 0.5 ? 'alternate' : 'alternate-reverse';

    useEffect(() => {
        setAnimationStyles({
            animationDelay: gradientDelay,
            animationDuration: gradientDuration,
            animationDirection: gradientDirection,
        });
    }, []);

    const gradientId = `awward-linearGradient-${id}`;
    const maskId = `awward-image-mask-${id}`;

    return (
        <svg viewBox={`0 0 ${width} ${height}`} className={styles.svg}>
            <defs>
                <linearGradient id={gradientId}>
                    <stop
                        className={styles.gradientStop1}
                        stopColor="#a247a7"
                        offset="0%"
                        style={animationStyles}
                    />
                    <stop
                        className={styles.gradientStop2}
                        stopColor="#6562c8"
                        offset="100%"
                        style={animationStyles}
                    />
                </linearGradient>
                <mask id={maskId}>
                    <rect
                        x="0"
                        y="0"
                        width={width}
                        height={height}
                        fill="black"
                    />
                    <image
                        x="0"
                        y="0"
                        width={width}
                        height={height}
                        xlinkHref={url}
                    />
                </mask>
            </defs>
            <rect
                x="0.5"
                y="0.5"
                width={width - 1}
                height={height - 1}
                fill={`url(#${gradientId})`}
                mask={`url(#${maskId})`}
            />
        </svg>
    );
};

export default Logo;
