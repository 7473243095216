import React from 'react';
import PropTypes from 'prop-types';

import FeaturedLink from 'components/ui/FeaturedLink';
import Text from 'components/ui/Text';

import styles from './Footer.scss';

const Footer = ({ street, city, email, phone }) => {
    return (
        <footer className={styles.footer}>
            <div className={styles.email}>
                <FeaturedLink href={`mailto:${email}`}>{email}</FeaturedLink>
            </div>

            <Text as="p" className={styles.phone} theme="definitionLists">
                {phone}
            </Text>
            <div className={styles.address}>
                <Text
                    as="p"
                    theme="definitionLists"
                    className={styles.streetAddress}
                >
                    {street}
                </Text>
                <Text
                    as="p"
                    theme="definitionLists"
                    className={styles.cityAddress}
                >
                    {city}
                </Text>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
};

export default Footer;
