import React from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TweenMax, Power3 } from 'gsap';

import Breakpoint from 'components/ui/Breakpoint';

import NavLink from './NavLink';

import styles from './Nav.scss';

class Nav extends React.Component {
    static propTypes = {
        isActive: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
    };

    menuHeight = 0;

    componentDidMount() {
        this.setMenuHeight();
        window.addEventListener('resize', this.setMenuHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setMenuHeight);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isActive === this.props.isActive) return;

        if (!prevProps.isActive && this.props.isActive) {
            this.animateIn();
        } else {
            this.animateOut();
        }
    }

    animateIn = () => {
        TweenMax.staggerFromTo(
            `.${styles.container} a`,
            1,
            { y: 50 },
            { y: 0, ease: Power3.easeOut, delay: 0.3 },
            0.065
        );
        TweenMax.staggerFromTo(
            `.${styles.container} a`,
            0.7,
            { autoAlpha: 0 },
            { autoAlpha: 1, ease: Power1.easeIn, delay: 0.3 },
            0.065
        );
    };

    animateOut = () => {
        TweenMax.fromTo(
            `.${styles.container} a`,
            0.5,
            { autoAlpha: 1 },
            { autoAlpha: 0, ease: Power3.easeOut }
        );
    };

    setMenuHeight = () => {
        this.menuHeight = window.innerHeight;
    };

    render() {
        const { isActive, onClick, router } = this.props;

        return (
            <Breakpoint name="desktop">
                {match => {
                    return (
                        <nav
                            className={cx(
                                styles.container,
                                isActive && styles.active
                            )}
                            onClick={onClick}
                            style={{
                                height: !match
                                    ? `${this.menuHeight}px`
                                    : 'initial',
                            }}
                        >
                            <NavLink
                                label="Work"
                                href="/work"
                                active={router.route === '/work'}
                            />
                            <NavLink
                                label="Studio"
                                href="/studio"
                                active={router.route === '/studio'}
                            />
                            <NavLink
                                label="Contact"
                                href="/contact"
                                active={router.route === '/contact'}
                            />
                        </nav>
                    );
                }}
            </Breakpoint>
        );
    }
}

export default withRouter(Nav);
