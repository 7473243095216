import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'routes';

import Text from 'components/ui/Text';

import styles from './CTA.scss';

class CTA extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        onMouseOver: PropTypes.func,
        onMouseOut: PropTypes.func,
    };

    render() {
        const { label, onMouseOver, onMouseOut } = this.props;
        return (
            <Link passHref route="/work">
                <Text
                    as="a"
                    theme="eyebrows"
                    className={styles.cta}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                >
                    {label}
                </Text>
            </Link>
        );
    }
}

export default CTA;
