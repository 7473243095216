import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { modulo } from 'utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import InterstitialItem from './Item';

import styles from './Interstitial.scss';

class Interstitial extends React.Component {
    state = {
        currentSlideIndex: 0,
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState(state => ({
                currentSlideIndex: modulo(
                    state.currentSlideIndex + 1,
                    this.props.items.length
                ),
            }));
        }, 4000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { className, items } = this.props;
        const { currentSlideIndex } = this.state;

        const currentItem = items[currentSlideIndex];

        const sectionClass = cx(styles.interstitial, className, {
            [styles.oneUpInterstitial]:
                currentItem.contentTypeId === 'interstitialFullBleedItem',
        });

        return (
            <TransitionGroup component="section" className={sectionClass}>
                <CSSTransition
                    timeout={1000}
                    classNames="interstitial-fade"
                    key={currentSlideIndex}
                >
                    <InterstitialItem item={currentItem} />
                </CSSTransition>
            </TransitionGroup>
        );
    }
}

Interstitial.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
};

export default Interstitial;
