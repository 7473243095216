import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ImageVideo from 'components/ui/ImageVideo';

import grid from 'styles/grid.scss';
import styles from './InlineVideoModule.scss';

const InlineVideoModule = ({
    className,
    inlineVideo: {
        videos,
        image: { file },
    },
}) => (
    <div className={cx(grid.container, styles.root, className)}>
        <ImageVideo image={file.url} videos={videos} />
    </div>
);

InlineVideoModule.propTypes = {
    className: PropTypes.string,
    inlineVideo: PropTypes.object,
};

export default InlineVideoModule;
