import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { TweenMax, Power1 } from 'gsap';

import VimeoPlayer from 'components/ui/VimeoPlayer';

import { getScrollParent, isMinScreenSize, getGridColumnWidth } from 'utils';

import grid from 'styles/grid.scss';
import styles from './Reel.scss';

class Reel extends Component {
    static propTypes = {
        reels: PropTypes.array.isRequired,
        reelIndex: PropTypes.number.isRequired,
        vimeoReel: PropTypes.string.isRequired,
    };

    state = {
        width: '0%',
        isVisible: false,
        isPosterLoaded: false,
    };

    ref = React.createRef();
    videoRef = React.createRef();

    componentDidMount() {
        this.observer = new IntersectionObserver(this.handleIntersection, {});
        this.observer.observe(this.ref.current);
        this.preloadPoster();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.isVisible &&
            this.state.isPosterLoaded &&
            (!prevState.isPosterLoaded || !prevState.isVisible)
        ) {
            this.fadeIn();
        }
    }

    componentWillUnmount() {
        this.observer.disconnect();
        if (this.scrollTarget) {
            this.scrollTarget.removeEventListener('scroll', this.handleScroll);
        }
        if (this.poster) {
            this.poster.onload = undefined;
        }
        if (this.fadeInTween) {
            this.fadeInTween.kill();
        }
    }

    preloadPoster() {
        const img = new Image();
        img.onload = () => {
            this.setState({ isPosterLoaded: true });
        };
        img.src = this.props.reels[this.props.reelIndex].image.file.url;
        this.posterLoadStart = Date.now();
        this.poster = img;
    }

    handleIntersection = ([entry]) => {
        this.setState(state => {
            return !state.isVisible && entry.isIntersecting
                ? { isVisible: true }
                : null;
        });
        if (entry.isIntersecting) {
            this.scrollTarget = getScrollParent(this.ref.current);
            this.scrollTarget.addEventListener('scroll', this.handleScroll);
            this.handleScroll();
        } else if (this.scrollTarget) {
            this.scrollTarget.removeEventListener('scroll', this.handleScroll);
        }
    };

    handleScroll = () => {
        const style = getComputedStyle(document.body);

        const gridColumn = getGridColumnWidth();
        const gridGap = parseInt(style.getPropertyValue('--grid-gap'), 10);
        const gridMargin = parseInt(
            style.getPropertyValue('--grid-margin'),
            10
        );

        const initialVideoWidth = isMinScreenSize('desktop')
            ? window.innerWidth - (gridMargin + gridGap + gridColumn) * 2 // grid-column: 2 / -2
            : window.innerWidth - gridMargin * 2; // grid-column: 1 / -1

        const minWidthPercent = initialVideoWidth / window.innerWidth;

        const rect = this.ref.current.getBoundingClientRect();
        const y = window.innerHeight - rect.top;

        const heightPercent = y / rect.height;

        const pct = minWidthPercent + (1 - minWidthPercent) * heightPercent;
        const width = `${Math.max(Math.min(pct * 100, 100), 0)}%`;

        this.setState({ width });
    };

    fadeIn() {
        const delay = (Date.now() - this.posterLoadStart) / 1000;
        this.fadeInTween = TweenMax.to(this.videoRef.current, 0.5, {
            opacity: 1,
            ease: Power1.easeIn,
            delay: Math.max(0.8 - delay, 0),
        });
    }

    render() {
        const { reels, reelIndex, vimeoReel } = this.props;
        const { width } = this.state;

        return (
            <section ref={this.ref} className={cx(grid.container, styles.reel)}>
                <div
                    className={styles.videoContainer}
                    style={{ width }}
                    ref={this.videoRef}
                >
                    <VimeoPlayer
                        poster={reels[reelIndex]}
                        videoId={vimeoReel}
                        buttonStyle="transparent"
                    />
                </div>
            </section>
        );
    }
}

export default Reel;
