import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Player from '@vimeo/player';

import PlayButton from 'components/ui/PlayButton';

import styles from './styles.scss';

class VimeoPlayer extends Component {
    state = {
        isPlaying: false,
        isInView: false,
    };

    player = null;
    playerRef = React.createRef();

    componentDidMount() {
        this.createPlayer();
        this.observer = new IntersectionObserver(this.handleIntersection, {});
        this.observer.observe(this.playerRef.current);
    }

    componentDidUpdate(prevProps, prevState) {
        const { isInView } = this.state;
        if (prevState.isInView !== isInView) {
            if (!isInView) {
                this.player.pause();
                this.setState({ isPlaying: false });
            }
        }
    }

    componentWillUnmount() {
        this.player.off('ended', this.playerEndedHandler);
        this.player.destroy();
    }

    createPlayer = () => {
        const { videoId } = this.props;

        const options = {
            id: videoId,
            byline: false,
            portrait: false,
            title: false,
            responsive: true,
            playsinline: false,
        };

        this.player = new Player(this.playerRef.current, options);
        this.player.on('ended', this.playerEndedHandler);
    };

    handleIntersection = ([entry]) => {
        this.setState(state => {
            if (state.isInView !== entry.isIntersecting) {
                return {
                    isInView: entry.isIntersecting,
                };
            }
            return null;
        });
    };

    playerEndedHandler = () => {
        this.setState({ isPlaying: false });
    };

    clickPosterHandler = () => {
        this.setState({
            isPlaying: true,
        });
        this.player.play();
    };

    renderPoster = () => {
        const {
            poster: {
                image: { file, title },
                videos,
            },
        } = this.props;

        if (typeof videos !== 'undefined') {
            return (
                <video width="100%" playsInline muted autoPlay loop>
                    {videos.map((video, index) => (
                        <source
                            key={`poster-${index}`}
                            src={video.file.url}
                            type={video.file.contentType}
                        />
                    ))}
                </video>
            );
        }

        return <img src={file.url} alt={title} />;
    };

    render() {
        const { color, buttonStyle } = this.props;
        const { isPlaying } = this.state;

        return (
            <div
                className={cx(styles.vimeoContainer, {
                    [styles.isPlaying]: isPlaying,
                })}
                onClick={this.clickPosterHandler}
                style={{ color: color }}
            >
                <div className={styles.vimeo} ref={this.playerRef} />
                <div className={styles.poster}>
                    {this.renderPoster()}
                    <PlayButton style={buttonStyle} />
                </div>
            </div>
        );
    }
}

VimeoPlayer.propTypes = {
    buttonStyle: PropTypes.string,
    color: PropTypes.string,
    poster: PropTypes.object.isRequired,
    videoId: PropTypes.string.isRequired,
};

VimeoPlayer.defaultProps = {
    buttonStyle: 'opaque',
    color: '#FFFFFF',
};

export default VimeoPlayer;
