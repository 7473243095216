export default function Viewport(_width, _height) {
    this.width = _width;
    this.height = _height;

    this.grid = {};
    this.camRange = { cellWidth: 0, cellHeight: 0 };

    this.set = (_x, _y) => {
        this.x = _x;
        this.y = _y;
        this.r = _y === 0 ? 0 : _x / _y;
    };

    this.setRowsCols = (_rows, _cols) => {
        this.rows = _rows;
        this.cols = _cols;
    };

    // Sets grid bounds, copied from BGManager
    this.setGrid = _newGrid => {
        this.grid = _newGrid;
    };

    this.setCamRange = (_cellWidth, _cellHeight) => {
        this.camRange.cellWidth = _cellWidth;
        this.camRange.cellHeight = _cellHeight;
    };
}
