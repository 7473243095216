import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tunnel } from 'react-tunnels';
import debounce from 'lodash/debounce';

import { tunnels } from 'utils/constants';

import Sticky from './Sticky';
import Header from './Header';

import styles from './StickyHeader.scss';

class StickyHeader extends Component {
    static propTypes = {
        view: PropTypes.string,
        scrollY: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };
        this.handleMenuVisibility = debounce(this.handleMenuVisibility, 500, {
            leading: true,
            trailing: false,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isMenuOpen !== this.state.isMenuOpen) {
            if (this.state.isMenuOpen) {
                this.disableScroll();
            }

            this.enableScroll();
        }
    }

    enableScroll = () => {
        document.body.removeEventListener('touchmove', this.onTouchMove, {
            passive: false,
        });
    };

    disableScroll = () => {
        document.body.addEventListener('touchmove', this.onTouchMove, {
            passive: false,
        });
    };

    onTouchMove = e => {
        e.preventDefault();
    };

    handleMenuVisibility = () => {
        this.setState(state => ({ isMenuOpen: !state.isMenuOpen }));
    };

    render() {
        const { scrollY, view } = this.props;
        const { isMenuOpen } = this.state;

        return (
            <div className={styles.stickyHeader}>
                <Sticky scrollY={scrollY} isMenuOpen={isMenuOpen}>
                    {stickyState => (
                        <Header
                            onClick={this.handleMenuVisibility}
                            view={view}
                            {...stickyState}
                        />
                    )}
                </Sticky>
            </div>
        );
    }
}

export const StickyHeaderTunnel = props => (
    <Tunnel id={tunnels.header} {...props} />
);

export default StickyHeader;
