import React from 'react';
import PropTypes from 'prop-types';

import FancyLink from 'components/ui/FancyLink';

import styles from './NavLink.scss';

const NavItem = ({ label, href, active }) => {
    return (
        <FancyLink href={href} className={styles.link} selected={active}>
            {label}
        </FancyLink>
    );
};

NavItem.propTypes = {
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
};

NavItem.defaultProps = {
    active: false,
};

export default NavItem;
