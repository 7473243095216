import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import Option from './Option';

import styles from './Question.scss';

class Question extends Component {
    static propTypes = {
        isTouch: PropTypes.bool.isRequired,
        question: PropTypes.array.isRequired,
        onClickAnswer: PropTypes.func.isRequired,
    };

    render() {
        const { isTouch, question, onClickAnswer } = this.props;

        return (
            <div className={styles.question}>
                <Option
                    text={question[0].title}
                    onClick={onClickAnswer(0)}
                    gif={question[0].gif.file.url}
                    isTouch={isTouch}
                />
                <div className={styles.or}>
                    <Text theme="listHeadline">or</Text>
                </div>
                <Option
                    text={question[1].title}
                    onClick={onClickAnswer(1)}
                    gif={question[1].gif.file.url}
                    isTouch={isTouch}
                />
            </div>
        );
    }
}

export default Question;
