import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TweenMax, Power1, Power3 } from 'gsap';
import random from 'lodash/random';
import { isMinScreenSize } from 'utils';

import Text from 'components/ui/Text';

import grid from 'styles/grid.scss';
import styles from './Hero.scss';

class Hero extends React.Component {
    static propTypes = {
        headline: PropTypes.string.isRequired,
        subheadline: PropTypes.string.isRequired,
        fontsLoaded: PropTypes.bool.isRequired,
    };

    state = {
        isReveal: true,
    };

    headRef = React.createRef();
    subRef = React.createRef();

    componentDidMount() {
        if (this.props.fontsLoaded) {
            this.animate();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.fontsLoaded && this.props.fontsLoaded) {
            this.animate();
        }
    }

    componentWillUnmount() {
        if (this.gradientTween) this.gradientTween.kill();
        this.slideTweens.forEach(tween => tween.kill());
        this.fadeTweens.forEach(tween => tween.kill());
    }

    animate() {
        const SplitText = require('utils/gsap/SplitText');
        const splitOptions = { type: 'lines', linesClass: styles.lines };
        this.head = new SplitText(this.headRef.current, splitOptions);
        this.sub = new SplitText(this.subRef.current, splitOptions);
        this.slideTweens = TweenMax.staggerFrom(
            [...this.head.lines, ...this.sub.lines],
            1.2,
            { y: 60, ease: Power3.easeOut, delay: 0.3 },
            0.075,
            this.staggerDone
        );
        this.fadeTweens = TweenMax.staggerTo(
            [...this.head.lines, ...this.sub.lines],
            0.5,
            { opacity: 1, ease: Power1.easeIn, delay: 0.3 },
            0.075
        );
        TweenMax.set([this.headRef.current, this.subRef.current], {
            opacity: 1,
        });
    }

    animateGradient = () => {
        if (!this.headRef.current) return;

        const gradientX = isMinScreenSize('tablet')
            ? random(-40, 140)
            : random(0, 100);
        const gradientY = random(0, 100);

        this.gradientTween = TweenMax.to(this.headRef.current, 2, {
            backgroundPositionX: `${gradientX}%`,
            backgroundPositionY: `${gradientY}%`,
            delay: 2,
            ease: Power1.easeInOut,
            onComplete: this.animateGradient,
        });
    };

    staggerDone = () => {
        this.head.revert();
        this.sub.revert();

        // Safari hack
        this.headRef.current.style.display = 'none';
        this.headRef.current.offsetHeight;
        this.headRef.current.style.display = '';

        TweenMax.fromTo(
            this.headRef.current,
            isMinScreenSize('tablet') ? 2 : 1,
            { backgroundPosition: '-240% 50%' },
            {
                backgroundPosition: isMinScreenSize('tablet')
                    ? '-40% 50%'
                    : '0% 50%',
                ease: Power1.easeInOut,
                onComplete: this.animateGradient,
            }
        );

        this.setState({ isReveal: false });
    };

    render() {
        const { headline, subheadline } = this.props;
        return (
            <section className={cx(grid.container, styles.hero)}>
                <Text
                    as="h2"
                    theme="largeLinks"
                    className={cx(styles.headline, {
                        [styles.headlineReveal]: this.state.isReveal,
                    })}
                    ref={this.headRef}
                >
                    {headline}
                </Text>
                <Text
                    as="h3"
                    theme="callout"
                    className={styles.subheadline}
                    ref={this.subRef}
                >
                    {subheadline}
                </Text>
            </section>
        );
    }
}

export default Hero;
