/*
 * RippleGen.js
 * ===========
 * GPGPU texture that generates ripple effects.
 * Takes mouse position, then renders effect in R+G channels.
 * Used by KeyPhoto to apply distortions.
 */

import * as THREE from 'three';

import rippleVert from './shaders/ripple.vert';
import rippleFrag from './shaders/ripple.frag';

export default class RippleGen {
    devMode = false;
    targetSwap = false;

    constructor(_subdivs, _mouse, _renderer) {
        this.subdivs = _subdivs;
        this.mouse = _mouse;
        this.renderer = _renderer;

        this.ogSize = this.renderer.getSize();

        // Set up render targets
        this.rTarget1 = new THREE.WebGLRenderTarget(
            this.subdivs.x,
            this.subdivs.y,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                stencilBuffer: false,
                depthBuffer: false,
                format: THREE.RGBAFormat,
                type: this.getDataType(),
                wrapS: THREE.ClampToEdgeWrapping,
                wrapT: THREE.ClampToEdgeWrapping,
            }
        );
        this.rTarget2 = this.rTarget1.clone();
        this.rTargetActive = this.rTarget1;
        this.rTargetInactive = this.rTarget2;

        // Set up scene for calculating spring
        this.scene = new THREE.Scene();
        this.cam = new THREE.Camera();
        this.cam.position.z = 1;

        const rezString = `vec2( ${this.subdivs.x.toFixed(
            1
        )}, ${this.subdivs.y.toFixed(1)} )`;
        const rippleGeom = new THREE.PlaneBufferGeometry(2, 2);
        const rippleMat = new THREE.RawShaderMaterial({
            uniforms: {
                mousePos: { value: this.mouse },
                mouseSize: { value: 10.0 },
                viscosityConstant: { value: 0.1 },
                heightmap: { value: null },
            },
            defines: {
                SHADER_NAME: 'RippleGenMaterial',
                RESOLUTION: rezString,
            },
            vertexShader: rippleVert,
            fragmentShader: rippleFrag,
            depthWrite: false,
        });
        this.uniHeightMap = rippleMat.uniforms.heightmap;

        this.rippleMesh = new THREE.Mesh(rippleGeom, rippleMat);
        this.scene.add(this.rippleMesh);

        // Set up dev view
        if (this.devMode) {
            this.devScene = new THREE.Scene();
            this.devCam = new THREE.Camera();
            this.devCam.position.z = 1;
            this.devMat = new THREE.MeshBasicMaterial({
                map: this.rTarget1.texture,
            });

            const devGeom = new THREE.PlaneBufferGeometry(2, 2);
            const devMesh = new THREE.Mesh(devGeom, this.devMat);

            this.devScene.add(devMesh);
        }
    }

    getDataType() {
        return /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
            ? THREE.HalfFloatType
            : THREE.FloatType;
    }

    // Deallocate memory from GPU
    dispose() {
        this.scene.remove(this.rippleMesh);
        this.rippleMesh.geometry.dispose();
        this.rippleMesh.material.dispose();
        this.rTarget1.dispose();
        this.rTarget2.dispose();
    }

    update() {
        this.targetSwap = !this.targetSwap;

        if (this.targetSwap) {
            this.rTargetActive = this.rTarget1;
            this.rTargetInactive = this.rTarget2;
        } else {
            this.rTargetActive = this.rTarget2;
            this.rTargetInactive = this.rTarget1;
        }

        this.uniHeightMap.value = this.rTargetInactive.texture;
        this.renderer.render(this.scene, this.cam, this.rTargetActive);

        if (this.devMode) {
            this.devMat.map = this.rTargetActive.texture;
            this.renderer.setViewport(
                0,
                0,
                this.subdivs.x * 2,
                this.subdivs.y * 2
            );
            this.renderer.render(this.devScene, this.devCam);
            this.renderer.setViewport(
                0,
                0,
                this.ogSize.width,
                this.ogSize.height
            );
        }

        return this.rTargetActive.texture;
    }
}
