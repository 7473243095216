/*
 This component is using two-pass rendering: https://reactjs.org/docs/react-dom.html#hydrate
 Use this component ONLY in rare scenarios where markup must change based on viewport size

 Favor CSS media queries whenever possible.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Media from 'react-media';
import breakpoints from 'styles/breakpoints.scss';

const breakpointsMap = {
    mobileOnly: { maxWidth: breakpoints.tablet },
    tablet: { minWidth: breakpoints.tablet },
    desktop: { minWidth: breakpoints.desktop },
    largeDesktop: { minWidth: breakpoints.largeDesktop },
    hugeDesktop: { minWidth: breakpoints.hugeDesktop },
};

class Breakpoint extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
    };

    state = { isClient: false };

    componentDidMount() {
        this.setState({ isClient: true });
    }

    render() {
        const { name, ...props } = this.props;
        const { isClient } = this.state;
        const query = breakpointsMap[name];

        return isClient && <Media {...props} query={query} />;
    }
}

export default Breakpoint;
