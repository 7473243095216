import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { modulo } from 'utils';
import { TweenMax } from 'gsap';

import styles from './WaveCover.scss';

export default class WaveCover extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        amplitude: PropTypes.number,
        waveLength: PropTypes.number,
        frequency: PropTypes.number,
        orientation: PropTypes.oneOf(['down', 'up']),
    };

    static defaultProps = {
        amplitude: 25,
        waveLength: 1440,
        frequency: 5,
        orientation: 'down',
    };

    svgRef = React.createRef();
    waveRef = React.createRef();
    offset = 0;

    componentDidMount() {
        this.observer = new IntersectionObserver(this.handleIntersection, {});
        this.observer.observe(this.svgRef.current);
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.raf);
        this.observer.disconnect();
    }

    handleIntersection = ([entry]) => {
        if (entry.isIntersecting) {
            this.animate();
        } else {
            cancelAnimationFrame(this.raf);
        }
    };

    animate = () => {
        const { frequency, waveLength } = this.props;
        this.offset = modulo(this.offset + frequency, waveLength * 2);
        if (this.waveRef.current) {
            TweenMax.set(this.waveRef.current, {
                x: this.offset - waveLength * 2,
            });
        }
        this.raf = requestAnimationFrame(this.animate);
    };

    get path() {
        const { amplitude, waveLength, orientation } = this.props;
        let path = `M 0 ${amplitude / 2}`;
        path += ` q ${waveLength / 4} ${-amplitude} ${waveLength / 2} 0`;
        for (let i = 1440 / waveLength + 3; i >= 0; i--) {
            path += ` t ${waveLength / 2} 0`;
        }
        if (orientation === 'down') {
            path += ' V 0';
            path += ' H 0';
            path += ` V ${amplitude / 2}`;
        } else {
            path += ` V ${amplitude}`;
            path += ' H 0';
            path += ' V 0';
        }
        return path;
    }

    render() {
        return (
            <div className={cx(styles.waveCover, this.props.className)}>
                <svg
                    width="1440px"
                    height="25px"
                    viewBox="0 0 1440 25"
                    preserveAspectRatio="none"
                    ref={this.svgRef}
                >
                    <g ref={this.waveRef}>
                        <path fill="currentColor" d={this.path} />
                    </g>
                </svg>
            </div>
        );
    }
}
