import React from 'react';
import PropTypes from 'prop-types';

import styles from './Brands.scss';

const Logo = ({ id, url, width, height, style }) => (
    <svg viewBox={`0 0 ${width} ${height}`} style={style}>
        <defs>
            <linearGradient id="linearGradient">
                <stop
                    className={styles.gradientStop1}
                    stopColor="#a247a7"
                    offset="0%"
                />
                <stop
                    className={styles.gradientStop2}
                    stopColor="#6562c8"
                    offset="100%"
                />
            </linearGradient>
            <mask id={`brand-mask-${id}`}>
                <rect x="0" y="0" width={width} height={height} fill="black" />
                <image
                    x="0"
                    y="0"
                    width={width}
                    height={height}
                    xlinkHref={url}
                />
            </mask>
        </defs>
        <rect
            x="0.5"
            y="0.5"
            width={width - 1}
            height={height - 1}
            fill="url(#linearGradient)"
            mask={`url(#brand-mask-${id})`}
        />
    </svg>
);

Logo.propTypes = {
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
};

export default Logo;
