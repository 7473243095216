import React from 'react';
import PropTypes from 'prop-types';

import GrabGallery from 'components/ui/GrabGallery';

import Logo from './Logo';

import styles from './Awards.scss';

const Awards = ({ list }) => (
    <GrabGallery className={styles.awards} listClassName={styles.list}>
        {list.map((award, i) => {
            const { url, details } = award.svg.file;
            const { width, height } = details.image;

            return (
                <li className={styles.item} key={`award-${i}`}>
                    <Logo id={i} url={url} width={width} height={height} />
                </li>
            );
        })}
    </GrabGallery>
);

Awards.propTypes = {
    list: PropTypes.array.isRequired,
};

export default Awards;
