import React from 'react';
import PropTypes from 'prop-types';

import isFunction from 'lodash/isFunction';
import wrap from 'lodash/wrap';
import forOwn from 'lodash/forOwn';
import last from 'lodash/last';

function proxyToParent(ctx, fn, eventName) {
    if (isFunction(ctx.props[eventName])) {
        const parentFn = ctx.props[eventName];
        return wrap(fn, function(func, e) {
            fn(e);
            parentFn(e);
        });
    }
    return fn;
}

function sign(num) {
    return num < 0 ? -1 : 1;
}

let timeoutId;
let data = [];
let direction = 0;
let inertia = false;
let lastTouchCoord = null;

class Wheeler extends React.Component {
    static propTypes = {
        children: PropTypes.element.isRequired,
        disable: PropTypes.bool,
        disableTouch: PropTypes.bool,
        onIdle: PropTypes.func,
        onInertia: PropTypes.func,
        onWheel: PropTypes.func.isRequired,
        sampleSize: PropTypes.number,
    };

    static defaultProps = {
        disable: false,
        disableTouch: false,
        sampleSize: 8,
        onInertia() {},
        onIdle() {},
    };

    isInertia(dy) {
        data.push(dy);
        let result = false;
        const len = data.length;
        if (len === 1) {
            direction = sign(dy);
        } else {
            const curDirection = sign(dy);
            if (direction !== curDirection) {
                // change of swipe direction
                data = [dy];
                direction = curDirection;
            } else {
                const sampleSize = this.props.sampleSize;
                if (len > sampleSize) {
                    let signCount = 0;
                    let equalCount = 0;
                    for (let i = len - sampleSize; i < len; i++) {
                        const dyPrev = data[i - 1];
                        const dyCur = data[i];
                        const ddy = dyCur - dyPrev;
                        if (ddy === 0) {
                            // weed out mouse wheels which always emit the same high delta (usually >= 100)
                            if (Math.abs(dyPrev) > 10 && Math.abs(dyCur) > 10) {
                                equalCount++;
                            }
                        } else if (sign(ddy) === direction) {
                            // when actively swiping, the signs of the first dy and subsequent ddys tend to be the same (accelerate).
                            // when inertia kicks in, the signs differ (decelerate).
                            signCount++;
                        }
                    }
                    // report inertia, when out of the latest [sampleSize] events
                    // - less than [sampleSize / 2] accelerated (most decelerated)
                    // - all showed some de-/acceleration for higher deltas
                    result =
                        signCount < Math.round(sampleSize / 2) &&
                        equalCount !== sampleSize;
                }
            }
        }
        return result;
    }

    onWheelTimeout = () => {
        this.props.onIdle(data.concat());
        inertia = false;
        data = [];
    };

    onWheel = event => {
        // from React renderers/dom/client/syntheticEvents/SyntheticWheelEvent.js:
        // > Browsers without "deltaMode" is reporting in raw wheel delta where
        // > one notch on the scroll is always +/- 120, roughly equivalent to
        // > pixels. A good approximation of DOM_DELTA_LINE (1) is 5% of
        // > viewport size or ~40 pixels, for DOM_DELTA_SCREEN (2) it is 87.5%
        // > of viewport size.
        let multiplicator = 1;
        if (event.deltaMode === 1) {
            multiplicator = window.innerHeight * 0.05;
        } else if (event.deltaMode === 2) {
            multiplicator = window.innerHeight * 0.875;
        }
        const dx = event.deltaX * multiplicator;
        const dy = event.deltaY * multiplicator;
        const d = Math.hypot(dx, dy);
        if (!this.isInertia(d)) {
            inertia = false;
            this.props.onWheel(event);
        } else if (!inertia) {
            inertia = true;
            this.props.onInertia(data.concat());
        }
        clearTimeout(timeoutId);
        timeoutId = setTimeout(this.onWheelTimeout, 100);
    };

    onTouchStart = event => {
        const changed = last(event.changedTouches);
        lastTouchCoord = {
            x: changed.screenX,
            y: changed.screenY,
        };
    };

    onTouchMove = event => {
        const changed = last(event.changedTouches);
        this.props.onWheel({
            isEnd: false,
            deltaX: lastTouchCoord.x - changed.screenX,
            deltaY: lastTouchCoord.y - changed.screenY,
        });
        lastTouchCoord = {
            x: changed.screenX,
            y: changed.screenY,
        };
        event.preventDefault();
        event.stopPropagation();
    };

    onTouchEnd = event => {
        const changed = last(event.changedTouches);
        this.props.onWheel({
            isEnd: true,
            deltaX: lastTouchCoord.x - changed.screenX,
            deltaY: lastTouchCoord.y - changed.screenY,
        });
    };

    render() {
        const { children, disable, disableTouch } = this.props;
        let events = {};

        if (!disable) {
            events = {
                onWheel: this.onWheel,
                onTouchStart: disableTouch ? null : this.onTouchStart,
                onTouchMove: disableTouch ? null : this.onTouchMove,
                onTouchEnd: disableTouch ? null : this.onTouchEnd,
            };

            // Make sure we keep parent events;
            forOwn(events, function(value, key) {
                events[key] = proxyToParent(children, value, key);
            });
        }

        return React.cloneElement(children, events);
    }
}

export default Wheeler;
