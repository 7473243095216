import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TweenMax, Power2, Power3 } from 'gsap';
import { isMinScreenSize } from 'utils';

import WaveCover from 'components/ui/WaveCover';
import SwipeIndicator from 'components/ui/SwipeIndicator';

import Details from './Details';
import PeopleView from './PeopleView';
import Controls from './Controls';

import { shuffle, mod } from 'utils';

import styles from './People.scss';

class People extends Component {
    static propTypes = {
        collaborators: PropTypes.array.isRequired,
        scrollTarget: PropTypes.string.isRequired,
    };

    // currentPerson -1 tells WebGL to exit detailed view
    state = {
        currentIndex: -1,
        inTransition: false,
        hadInteraction: false,
    };

    canvasRef = React.createRef();

    constructor(props) {
        super(props);

        // Copy & shuffle collaborators for randomized experience
        this.allPeople = props.collaborators.slice();
        this.direction = 1;

        shuffle(this.allPeople);
    }

    componentDidMount() {
        this.view = new PeopleView(
            this.canvasRef.current,
            this.allPeople,
            this.clickCallback,
            this.handleCanvasInteraction
        );

        this.observer = new IntersectionObserver(this.handleIntersection, {});
        this.observer.observe(this.canvasRef.current);

        this.scrollTarget = document.querySelector(
            `.${this.props.scrollTarget}`
        );
        this.scrollTarget.addEventListener('scroll', this.handleScroll);

        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentIndex } = this.state;
        const prevPerson = prevState.currentIndex;

        if (prevPerson !== currentIndex) {
            // Index of -1 means hide detailed view
            if (currentIndex === -1) {
                this.view.hidePerson();
            } else {
                this.view.goToPerson(currentIndex, this.direction);
            }
        }
    }

    componentWillUnmount() {
        this.observer.disconnect();

        if (this.swipeIndicatorTO) clearTimeout(this.swipeIndicatorTO);

        this.view.kill();
        this.scrollTarget.removeEventListener('scroll', this.handleScroll);

        window.removeEventListener('resize', this.handleResize);
    }

    handleIntersection = ([entry]) => {
        if (entry.isIntersecting) {
            this.swipeIndicatorTO = setTimeout(() => {
                this.setState({
                    hadInteraction: true,
                });
            }, 5000);
        }
    };

    handleScroll = () => {
        this.view.onPageScroll();
    };

    handleResize = () => {
        if (this.state.currentIndex !== -1) {
            if (isMinScreenSize('desktop')) {
                this.view.goToPerson(this.state.currentIndex, this.direction);
            } else {
                this.view.hidePerson();
            }
        }

        const detail = document.querySelector(
            `.${styles.detailsContainerInner}`
        );

        // need to reset inline styles setted on animation
        const imagePct = isMinScreenSize('tabletLandscape')
            ? 0.25
            : isMinScreenSize('tablet')
            ? 0.45
            : 0.7;

        const picture = detail.querySelector('img');
        const pictureWidth = window.innerWidth * imagePct;

        if (picture) {
            TweenMax.set(picture.parentNode, {
                width: pictureWidth,
                height: pictureWidth * 1.333,
            });

            TweenMax.set(picture, { minWidth: pictureWidth });
        }

        const detailHeight = detail.querySelector('div').clientHeight;
        TweenMax.set(detail, { paddingTop: detailHeight });
    };

    handleCanvasInteraction = () => {
        this.setState({
            hadInteraction: true,
        });
    };

    /////////////////////// PREV/NEXT SCROLLING ///////////////////////
    // Clicking will return person index, or -1
    clickCallback = newIndex => {
        // Index of -1 means hide detailed view
        this.performPersonChange(newIndex);
    };

    // Scroll to previous person
    personPrev = () => {
        const newIndex = mod(
            this.state.currentIndex - 1,
            this.allPeople.length
        );

        this.direction = -1;
        this.performPersonChange(newIndex);
    };

    // Scroll to next person
    personNext = () => {
        const newIndex = mod(
            this.state.currentIndex + 1,
            this.allPeople.length
        );

        this.direction = 1;
        this.performPersonChange(newIndex);
    };

    // Closes person details
    closeDetail = () => {
        this.performPersonChange(-1);
    };

    // Triggers transition animation
    performPersonChange = newIndex => {
        // Ignore if still inTransition
        if (this.state.inTransition) return;

        this.setState({
            currentIndex: newIndex,
        });
    };

    transitionComplete = () => {
        this.setState({
            inTransition: false,
        });
    };

    onEntering = node => {
        if (!node) return;

        const isAppearing = node.parentNode.childNodes.length === 2;

        const text = node.querySelectorAll('p');
        const picture = node.querySelector('img');

        this.setState({ inTransition: true }, () => {
            if (picture) {
                // need to set the image size inline to keep layout
                // during width transition for cliping animation.
                const pictureWidth = picture.parentNode.clientWidth;

                TweenMax.set(picture.parentNode, {
                    height: pictureWidth * 1.333,
                });
                TweenMax.set(picture, { minWidth: pictureWidth });

                // Is is appeaing we play a clipping animation
                if (isAppearing) {
                    TweenMax.from(picture.parentNode, 0.5, {
                        width: 0.1,
                        delay: 0.3,
                        ease: Power3.easeOut,
                    });
                } else {
                    // otherwise it crossfades
                    TweenMax.from(picture, 0.5, {
                        autoAlpha: 0,
                        ease: Power3.easeOut,
                    });
                }
            }

            TweenMax.staggerFrom(
                text,
                0.4,
                {
                    y: 20,
                    autoAlpha: 0,
                    delay: isAppearing ? 0.3 : 0.7,
                    ease: Power3.easeOut,
                    onStart: () => {
                        TweenMax.set(`.${styles.detailsContainerInner}`, {
                            paddingTop: node.clientHeight,
                        });
                    },
                },
                0.1
            );

            TweenMax.killDelayedCallsTo(this.transitionComplete);
            TweenMax.delayedCall(0.7, this.transitionComplete);
        });
    };

    onExiting = node => {
        if (!node) return;

        const isClosing = node.parentNode.childNodes.length === 2;

        const text = node.querySelectorAll('p');
        const picture = node.querySelector('img');

        this.setState({ inTransition: true }, () => {
            if (picture) {
                // clipping when closing detail view
                if (isClosing) {
                    TweenMax.to(picture.parentNode, 0.5, {
                        width: 0,
                        ease: Power2.easeIn,
                    });
                } else {
                    // otherwise crossfade
                    TweenMax.to(picture, 0.5, {
                        autoAlpha: 0,
                        ease: Power2.easeIn,
                    });
                }
            }

            TweenMax.set(`.${styles.detailsContainerInner}`, {
                paddingTop: node.clientHeight,
            });

            TweenMax.staggerTo(
                text,
                0.3,
                { y: -20, autoAlpha: 0, ease: Power3.easeOut },
                0.05
            );

            TweenMax.killDelayedCallsTo(this.transitionComplete);
            TweenMax.delayedCall(1.4, this.transitionComplete);
        });
    };

    render() {
        const { currentIndex, hadInteraction } = this.state;

        const current = currentIndex === -1 ? {} : this.allPeople[currentIndex];
        const controlVisible = currentIndex !== -1;

        return (
            <section className={styles.root}>
                <WaveCover className={styles.waveTop} />

                <div ref={this.canvasRef} className={styles.canvasContainer} />

                <div className={styles.detailsContainer}>
                    <div className={styles.detailsContainerInner}>
                        <TransitionGroup component={null}>
                            <Transition
                                timeout={{
                                    appear: 0,
                                    enter: 700,
                                    exit: 1400,
                                }}
                                key={current.texLocation}
                                onEntering={this.onEntering}
                                onExiting={this.onExiting}
                            >
                                {() => <Details data={current} />}
                            </Transition>
                        </TransitionGroup>
                        <Controls
                            visible={controlVisible}
                            onClickNext={this.personNext}
                            onClickGrid={this.closeDetail}
                            onClickPrev={this.personPrev}
                        />
                    </div>
                </div>

                <SwipeIndicator isVisible={!hadInteraction} />

                <WaveCover
                    debug
                    className={styles.waveBottom}
                    orientation="up"
                />
            </section>
        );
    }
}

export default People;
