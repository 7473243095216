import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './ImageVideo.scss';

const ImageVideo = ({
    image,
    videos,
    className,
    style,
    isVisible,
    isActiveSlide,
}) => {
    const imageVideoClass = cx(styles.imageVideo, className);
    const video = useRef();
    useEffect(() => {
        if (video.current) {
            if (video.current.id === 'active') {
                video.current.play();
            } else {
                video.current.pause();
            }
        }
    }, [isVisible, isActiveSlide]);

    if (isVisible && videos && videos.length) {
        return (
            <div className={imageVideoClass} style={style}>
                <video
                    id={isActiveSlide ? 'active' : null}
                    ref={video}
                    poster={image}
                    playsInline
                    loop
                    muted
                >
                    {videos.map((video, i) => (
                        <source
                            key={i}
                            src={video.file.url}
                            type={video.file.contentType}
                        />
                    ))}
                </video>
            </div>
        );
    }
    return (
        <div className={imageVideoClass} style={style}>
            <img src={image} />
        </div>
    );
};

ImageVideo.propTypes = {
    image: PropTypes.string.isRequired,
    videos: PropTypes.array,
    className: PropTypes.string,
    style: PropTypes.object,
    isVisible: PropTypes.bool,
    isActiveSlide: PropTypes.bool,
};

ImageVideo.defaultProps = {
    isVisible: true,
    isActiveSlide: true,
};

export default ImageVideo;
