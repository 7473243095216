import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TweenMax, Power1, Power3 } from 'gsap';
import random from 'lodash/random';

import Text from 'components/ui/Text';
import WaveCover from 'components/ui/WaveCover';

import grid from 'styles/grid.scss';
import styles from './Hero.scss';

class Hero extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        copy: PropTypes.string.isRequired,
        fontsLoaded: PropTypes.bool.isRequired,
        gradient: PropTypes.array.isRequired,
        project: PropTypes.object.isRequired,
    };

    heroRef = React.createRef();
    textRef = React.createRef();
    eyebrowRef = React.createRef();

    componentDidMount() {
        if (this.props.fontsLoaded) {
            this.animate();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.fontsLoaded && this.props.fontsLoaded) {
            this.animate();
        }
    }

    componentWillUnmount() {
        if (this.gradientTween) this.gradientTween.kill();
        this.heroTween.kill();
        this.slideTweens.forEach(textTween => textTween.kill());
        this.fadeTweens.forEach(textTween => textTween.kill());
    }

    animate() {
        this.heroTween = TweenMax.to(this.heroRef.current, 1, {
            transform: 'translate3d(0, 0vh, 0)',
            ease: Power3.easeOut,
            delay: 0.6,
        });
        const SplitText = require('utils/gsap/SplitText');
        this.splitText = new SplitText(this.textRef.current, {
            type: 'lines',
            linesClass: styles.lines,
        });

        const staggerArray = this.splitText.lines;
        staggerArray.unshift(this.eyebrowRef.current);

        this.slideTweens = TweenMax.staggerFrom(
            staggerArray,
            1,
            { y: 50, ease: Power3.easeOut, delay: 0.8 },
            0.065,
            this.staggerDone
        );
        this.fadeTweens = TweenMax.staggerTo(
            staggerArray,
            0.7,
            { opacity: 1, ease: Power1.easeIn, delay: 0.8 },
            0.065
        );

        TweenMax.set(this.textRef.current, { opacity: 1 });
    }

    animateGradient = () => {
        if (!this.textRef.current) return;

        const gradientX = random(-40, 140);
        const gradientY = random(0, 100);

        this.gradientTween = TweenMax.to(this.textRef.current, 3.5, {
            backgroundPositionX: `${gradientX}%`,
            backgroundPositionY: `${gradientY}%`,
            ease: Power1.easeInOut,
            onComplete: this.animateGradient,
        });
    };

    staggerDone = () => {
        this.splitText.revert();

        // Safari hack
        this.textRef.current.style.display = 'none';
        this.textRef.current.offsetHeight;
        this.textRef.current.style.display = '';

        TweenMax.fromTo(
            this.textRef.current,
            2,
            { backgroundPosition: '-240% 50%' },
            {
                backgroundPosition: '-40% 50%',
                onComplete: this.animateGradient,
            }
        );
    };

    render() {
        const { className, copy, gradient, project } = this.props;
        const [c1, c2] = gradient;
        const style = {
            backgroundColor: c2,
            backgroundImage: `radial-gradient(circle at center, ${c1}, ${c2})`,
        };
        return (
            <section className={cx(styles.hero, className)} ref={this.heroRef}>
                <div className={cx(grid.container, styles.container)}>
                    <div className={styles.textContainer}>
                        <Text
                            as="p"
                            theme="eyebrows"
                            className={styles.breadcrumb}
                            ref={this.eyebrowRef}
                        >
                            {project.client}
                            &nbsp;/&nbsp;
                            {project.type}
                        </Text>
                        <Text
                            as="h3"
                            theme="callout"
                            className={styles.copy}
                            style={style}
                            ref={this.textRef}
                        >
                            {copy}
                        </Text>
                    </div>
                </div>
                <WaveCover />
            </section>
        );
    }
}

export default Hero;
