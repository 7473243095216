import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';

import styles from './Question.scss';

class Option extends Component {
    static propTypes = {
        isTouch: PropTypes.bool.isRequired,
        text: PropTypes.string.isRequired,
        gif: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    state = {
        isActive: false,
    };

    ref = React.createRef();

    onMouseEnter = () => {
        this.setState({
            isActive: true,
        });
    };

    onMouseLeave = () => {
        this.setState({
            isActive: false,
        });
    };

    onMouseMove = event => {
        const rect = this.ref.current.getBoundingClientRect();

        const width = this.ref.current.offsetWidth;
        const height = this.ref.current.offsetHeight;

        const left = rect.left;
        const top = rect.top;

        const pctX = (event.nativeEvent.x - left) / width;
        const pctY = (event.nativeEvent.y - top) / height;

        // changes the range of movement of the image
        const range = 100;

        const posX = range * pctX - range / 2;
        const posY = (range * pctY - range) / 2;

        this.setState({
            isActive: true,
            style: {
                transform: `translate(${posX}px, ${posY}px)`,
            },
        });
    };

    render() {
        const { isTouch, text, gif, onClick } = this.props;
        const { isActive, style } = this.state;
        const className = cx(styles.optionContainer, {
            [styles.isActive]: isActive,
        });
        return (
            <div
                ref={this.ref}
                onMouseEnter={isTouch ? undefined : this.onMouseEnter}
                onMouseLeave={isTouch ? undefined : this.onMouseLeave}
                onMouseMove={isTouch ? undefined : this.onMouseMove}
                className={className}
            >
                <div onClick={onClick} className={styles.imgContainer}>
                    <img src={gif} alt={text} style={style} />
                </div>
                <Text
                    className={styles.option}
                    theme="callout"
                    onClick={isTouch ? onClick : undefined}
                >
                    <span className={styles.optionInner}>{text}</span>
                </Text>
            </div>
        );
    }
}

export default Option;
