import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Skills from './Skills';

import grid from 'styles/grid.scss';
import styles from './Capabilities.scss';

class Capabilities extends Component {
    static proptypes = {
        title: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
        skillsets: PropTypes.array.isRequired,
    };

    render() {
        const { title, body, skillsets } = this.props;

        return (
            <section className={styles.capabilities}>
                <div className={cx(grid.container, styles.textBlock)}>
                    <Text as="h3" theme="callout" className={styles.title}>
                        {title}
                    </Text>
                    <Text
                        as="p"
                        theme="bodyCopy"
                        className={styles.description}
                    >
                        {body}
                    </Text>
                </div>
                <div className={cx(grid.container, styles.skills)}>
                    <Skills
                        className={styles.skillsList}
                        skillsets={skillsets}
                    />
                </div>
            </section>
        );
    }
}

export default Capabilities;
