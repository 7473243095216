import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import useIntersection from 'hooks/useIntersection';

import ImageVideo from 'components/ui/ImageVideo';
import GradientSlide from './GradientSlide';

import styles from './Interstitial.scss';

const InterstitialItem = ({ item }) => {
    const ref = useRef();
    const [ isVisible, setIsVisible ] = useState(false);
    useIntersection(ref, isVisible => setIsVisible(isVisible));
    const { media } = item;
    return (
        <div className={styles.interstitialItem} ref={ref}>
            <ImageVideo
                className={styles.videoSlide}
                image={media.image.file.url}
                videos={media.videos}
                isVisible={isVisible}
            />
            {item.contentTypeId === 'interstitialSplitItem' && (
                <GradientSlide
                    image={item.transparentImage.file.url}
                    gradient={[item.gradientColor1, item.gradientColor2]}
                />
            )}
        </div>
    );
};

InterstitialItem.propTypes = {
    item: PropTypes.object,
};

export default InterstitialItem;
