import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Breakpoint from 'components/ui/Breakpoint';

import Logo from './Logo';

import grid from 'styles/grid.scss';
import styles from './Brands.scss';

class Brands extends React.Component {
    static propTypes = {
        list: PropTypes.array.isRequired,
    };

    state = {
        currentPage: 0,
        activeBrand: -1,
        style: {},
    };

    wrapperRef = React.createRef();
    listRef = React.createRef();

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentPage !== this.state.currentPage) {
            this.updateNavigation();
        }
    }

    handleMouseEnter = brandIndex => () => {
        this.setState({
            activeBrand: brandIndex,
        });
    };

    handleMouseLeave = () => {
        this.setState({
            activeBrand: -1,
        });
    };

    handleMouseMove = event => {
        if (!event.currentTarget) {
            return;
        }

        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();
        const width = target.offsetWidth;
        const height = target.offsetHeight;
        const left = rect.left;
        const top = rect.top;

        const wrapperRect = this.wrapperRef.current.getBoundingClientRect();
        const dx = left - wrapperRect.left;
        const dy = top - wrapperRect.top;

        const pctX = (event.nativeEvent.x - left) / width;
        const pctY = (event.nativeEvent.y - top) / height;

        // changes the range of movement of the image
        const range = 100;

        const posX = dx + range * pctX - range / 2;
        const posY = dy + (range * pctY - range) / 2;

        this.setState({
            style: {
                transform: `translate(${posX}px, ${posY}px)`,
            },
        });
    };

    handleClickNavigation = pageIndex => () => {
        this.setState({
            currentPage: pageIndex,
        });
    };

    handleListScroll = event => {
        const { scrollLeft, clientWidth } = event.nativeEvent.target;
        const scrollPage = scrollLeft / clientWidth;

        if (scrollPage % 1 === 0) {
            this.setState({
                currentPage: scrollPage,
            });
        }
    };

    updateNavigation = () => {
        const { currentPage } = this.state;
        const listWidth = this.listRef.current.clientWidth;

        this.listRef.current.scrollTo(currentPage * listWidth, 0);
    };

    renderLogo() {
        const { activeBrand } = this.state;
        if (activeBrand >= 0) {
            const { style } = this.state;
            const { list } = this.props;
            const brand = list[activeBrand];
            const { url, details } = brand.svg.file;
            const { width, height } = details.image;
            return (
                <div className={styles.logoContainer}>
                    <div className={styles.logo}>
                        <Logo
                            id={activeBrand}
                            url={url}
                            style={style}
                            width={width}
                            height={height}
                        />
                    </div>
                </div>
            );
        }
        return null;
    }

    renderListItem = (id, brand, isDesktop = false) => {
        const { activeBrand } = this.state;
        const className = cx(styles.brand, {
            [styles.active]: activeBrand === id,
        });
        return (
            <Text
                key={`brand-${id}`}
                as="li"
                theme="definitionLists"
                className={className}
                onMouseEnter={isDesktop ? this.handleMouseEnter(id) : undefined}
                onMouseLeave={isDesktop ? this.handleMouseLeave : undefined}
                onMouseMove={isDesktop ? this.handleMouseMove : undefined}
            >
                {brand.name}
            </Text>
        );
    };

    renderDesktopList() {
        const { list } = this.props;
        return (
            <div className={styles.wrapper} ref={this.wrapperRef}>
                <ul className={styles.brandsList}>
                    {list.map((brand, i) => {
                        return this.renderListItem(i, brand, true);
                    })}
                </ul>
                {this.renderLogo()}
            </div>
        );
    }

    renderMobileList = () => {
        const { list } = this.props;
        const { currentPage } = this.state;

        return (
            <React.Fragment>
                <ul
                    className={styles.brandsList}
                    ref={this.listRef}
                    onScroll={this.handleListScroll}
                >
                    <span>
                        {list.map((brand, i) => {
                            if (i < 6) return this.renderListItem(i, brand);
                        })}
                    </span>
                    <span>
                        {list.map((brand, i) => {
                            if (i >= 6 && i < 12)
                                return this.renderListItem(i, brand);
                        })}
                    </span>
                    <span>
                        {list.map((brand, i) => {
                            if (i >= 12) return this.renderListItem(i, brand);
                        })}
                    </span>
                </ul>
                <nav className={styles.navigation}>
                    {list.map(
                        (_, i) =>
                            i % 6 === 0 && (
                                <button
                                    key={`bullet-${i / 6}`}
                                    className={cx(styles.bullet, {
                                        [styles.active]: currentPage === i / 6,
                                    })}
                                    onClick={this.handleClickNavigation(i / 6)}
                                />
                            )
                    )}
                </nav>
            </React.Fragment>
        );
    };

    render() {
        return (
            <Breakpoint name="tablet">
                {match => (
                    <div className={cx(styles.brands, match && grid.container)}>
                        {match
                            ? this.renderDesktopList()
                            : this.renderMobileList()}
                    </div>
                )}
            </Breakpoint>
        );
    }
}

export default Brands;
