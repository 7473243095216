import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';

import grid from 'styles/grid.scss';
import styles from './ClientsIntro.scss';

const ClientsIntro = ({ tagline, title }) => (
    <section className={cx(grid.container, styles.clientsIntro)}>
        <Text as="h5" theme="eyebrows" className={styles.tagline}>
            {tagline}
        </Text>
        <Text as="h3" theme="callout" className={styles.title}>
            {title}
        </Text>
    </section>
);

ClientsIntro.propTypes = {
    tagline: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default ClientsIntro;
