export default function PeopleLoader(people, gridTex, bgnd, options = {}) {
    this.tileManager = bgnd;
    this.gridTex = gridTex;
    this.people = people;

    const { rows, cols } = this.tileManager.buildGrid();

    this.loadIndex = 0;
    this.gridRows = rows;
    this.gridColumns = cols;
    this.maxTextureSlots = this.gridRows * this.gridColumns;
    this.options = {
        chunkSize: 5,
        pause: 250,
        onComplete: () => {},
        onChunkComplete: () => {},
        ...options,
    };

    const loadChunk = () => {
        const from = this.loadIndex;
        const to = Math.min(
            this.loadIndex + this.options.chunkSize,
            this.maxTextureSlots
        );

        if (to <= from) {
            this._onComplete();
            return;
        }

        const chunk = this.people.slice(from, to);
        const promises = chunk.map((person, i) => {
            const index = from + i;
            const url = person.picture.file.url;
            const result = this.gridTex.drawImageByIndex(url, index);

            person.imgElem = result.imgElem;
            person.texLocation = index;

            return result.imgPromise;
        });

        Promise.all(promises).then(() => {
            if (this.isDisposed) return;

            this.loadIndex = to;
            this.gridTex.updateTexture();
            this.tileManager.addToGrid(from, chunk, this.gridTex);

            onChunkComplete();

            if (this.loadIndex < this.people.length) {
                this.timeout = setTimeout(() => {
                    loadChunk();
                }, this.options.pause);
            } else {
                onComplete();
            }
        });
    };

    const onChunkComplete = () => {
        // console.log('-> chunk completed');
        if (this.options.onChunkComplete && !this.isDisposed) {
            this.options.onChunkComplete();
        }
    };

    const onComplete = () => {
        // console.log('-> all completed');
        if (this.options.onComplete && !this.isDisposed) {
            this.options.onComplete();
        }
    };

    this.load = () => loadChunk();

    this.loadDetail = function(index) {
        const person = this.people[index];

        if (person.imgElemDetailPromise) return person.imgElemDetailPromise;

        return (person.imgElemDetailPromise = new Promise(resolve => {
            if (!person.pictureDetail || !person.pictureDetail.file) {
                return;
            }

            resolve(person.imgElem);

            const { url } = person.pictureDetail.file;
            const img = new Image();
            img.crossOrigin = 'anonymous';

            img.onload = () => resolve(img);
            img.onerror = () => {
                console.warn(`Image not found at ${url}`); // eslint-disable-line
                resolve(person.imgElem);
            };
            img.src = url;
        }));
    };

    this.dispose = function() {
        this.isDisposed = true;
        clearTimeout(this.timeout);
    };
}
