/*
 * Tile.js
 * ===========
 * An individual background photo.
 * Draws photo on left/right texture then slides UVs to display it
 * Controlled by BGManager.
 */

import * as THREE from 'three';

import vShader from './shaders/tile.vert';
import fShader from './shaders/tile.frag';

export default class ColorTile {
    static get WIDTH() {
        return 1;
    }
    static get HEIGHT() {
        return 1.3333333333333333;
    }
    static get INV_HEIGHT() {
        return 0.75;
    }
    static get ZOOM_NONE() {
        return 1;
    }
    static get ZOOM_SM() {
        return 1.2;
    }
    static get ZOOM_LG() {
        return 1.5;
    }

    constructor(_indexX, _indexY, _person, _viewport) {
        this.x = _indexX;
        this.y = _indexY;
        this.data = _person;
        this.viewport = _viewport;

        this.slidePos = 0;
        this.currentTex = this.data.texLocation;
        this.gridCenter = { x: 0, y: 0 };

        const geom = new THREE.PlaneBufferGeometry(
            ColorTile.WIDTH,
            ColorTile.HEIGHT,
            6,
            6
        );

        const texture = new THREE.TextureLoader().load(
            '/static/images/gradient.jpg',
            () => {
                texture.needsUpdate = true;
            }
        );

        const material = new THREE.RawShaderMaterial({
            uniforms: {
                alpha: { value: 1 },
                blackNWhite: { value: 1 },
                map: { value: texture },
                slider: { value: 0 },
                texLoc0: { value: this.currentTex },
                texLoc1: { value: 0 },
                time: { value: 0 },
                zoom: { value: ColorTile.ZOOM_SM },
            },
            defines: {
                SHADER_NAME: 'ColorTile',
                ZOOM_ANCHOR: 'vec2(0.5, 0.8)',
            },
            vertexShader: vShader,
            fragmentShader: fShader,
            depthWrite: false,
            depthTest: false,
            transparent: true,
        });

        this.uniforms = material.uniforms;

        this.plane = new THREE.Mesh(geom, material);
        this.plane.matrixAutoUpdate = false;

        this.setPosition();
    }

    // ******************* PRIVATE METHODS ******************* //
    setPosition(_x, _y) {
        this.x = _x !== undefined ? _x : this.x;
        this.y = _y !== undefined ? _y : this.y;

        this.plane.position.set(
            (this.x + 0.5 - this.viewport.cols / 2) * ColorTile.WIDTH,
            (this.y + 0.5 - this.viewport.rows / 2) * ColorTile.HEIGHT,
            0
        );
        this.plane.updateMatrix();
    }

    // Deallocate memory from GPU
    dispose() {
        this.plane.geometry.dispose();
        this.plane.material.dispose();
    }
}
