import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

const Skills = ({ skillsets, className }) => (
    <ul className={className}>
        {skillsets.map((set, i) => (
            <li key={`set-${i}`}>
                <img src={`/static/studio/capabilities/${set.type}.svg`} alt="" />
                <ul>
                    {set.capabilities.map((name, i) => (
                        <Text
                            key={`skill-${i}`}
                            as="li"
                            theme="definitionLists"
                        >
                            {name}
                        </Text>
                    ))}
                </ul>
            </li>
        ))}
    </ul>
);

Skills.propTypes = {
    className: PropTypes.string.isRequired,
    skillsets: PropTypes.array.isRequired,
};

export default Skills;
