/*
 * Tile.js
 * ===========
 * An individual background photo.
 * Draws photo on left/right texture then slides UVs to display it
 * Controlled by BGManager.
 */

import * as THREE from 'three';
import { TweenLite, Power2, Linear, Power4 } from 'gsap';

import vShader from './shaders/tile.vert';
import fShader from './shaders/tile.frag';

import { mod } from 'utils';

export default class Tile {
    static get WIDTH() {
        return 1;
    }
    static get HEIGHT() {
        return 1.3333333333333333;
    }
    static get INV_HEIGHT() {
        return 0.75;
    }
    static get ZOOM_NONE() {
        return 1;
    }
    static get ZOOM_SM() {
        return 1.2;
    }
    static get ZOOM_LG() {
        return 1.5;
    }

    constructor(_indexX, _indexY, _person, _gridTexture, _viewport) {
        this.x = _indexX;
        this.y = _indexY;
        this.person = _person;
        this.gridTexture = _gridTexture;
        this.viewport = _viewport;

        this.slidePos = 0;
        this.currentTex = this.person.texLocation;
        this.gridCenter = { x: 0, y: 0 };

        const geom = new THREE.PlaneBufferGeometry(
            Tile.WIDTH,
            Tile.HEIGHT,
            6,
            6
        );
        const mat = new THREE.RawShaderMaterial({
            uniforms: {
                alpha: { value: 0 },
                blackNWhite: { value: 1 },
                map: { value: this.gridTexture },
                slider: { value: 0 },
                texLoc0: { value: this.currentTex },
                texLoc1: { value: 0 },
                time: { value: 0 },
                zoom: { value: Tile.ZOOM_SM },
            },
            defines: {
                SHADER_NAME: 'TileMaterial',
                ZOOM_ANCHOR: 'vec2(0.5, 0.8)',
            },
            vertexShader: vShader,
            fragmentShader: fShader,
            depthWrite: false,
            depthTest: false,
            transparent: true,
        });

        // Uniform shortcuts
        const unis = mat.uniforms;
        this.uTime = unis.time;
        this.uAlpha = unis.alpha;
        this.uBNW = unis.blackNWhite;
        this.uSlider = unis.slider;
        this.uZoom = unis.zoom;
        this.uTex0 = unis.texLoc0;
        this.uTex1 = unis.texLoc1;

        this.plane = new THREE.Mesh(geom, mat);
        this.plane.matrixAutoUpdate = false;

        this.setPosition();
    }

    // ******************* PRIVATE METHODS ******************* //
    slide(_texLocation, _style) {
        this.currentTex = _texLocation;

        // Set even texture if sliding to even
        if (mod(this.slidePos, 2) === 0) {
            this.uTex0.value = _texLocation;
        } else {
            // Set odd texture if to odd
            this.uTex1.value = _texLocation;
        }

        TweenLite.to(this.uSlider, 1.0, {
            value: this.slidePos,
            delay: this.getDelayStyle(_style),
            ease: Power2.easeInOut,
        });
    }

    getDelayStyle(_styleID) {
        const halfCols = (this.viewport.cols - 1) / 2;
        const modX = mod(this.x, this.viewport.cols);
        let start;

        switch (_styleID) {
            case 0: // Left-right delay
                start =
                    this.viewport.grid.midX -
                    this.viewport.camRange.cellWidth / 2;
                return (this.x - start) / this.viewport.cols;
            case 1: // Right-left delay
                start =
                    this.viewport.grid.midX +
                    this.viewport.camRange.cellWidth / 2;
                return (start - this.x) / this.viewport.cols;
            case 2: // Center curtain delay
                return Math.abs(halfCols - modX) / 10;
        }
    }

    getDist(_srcX, _srcY) {
        return Math.abs(this.x - _srcX) + Math.abs(this.y - _srcY);
    }

    // ******************* PUBLIC METHODS ******************* //
    // Fade in from transparent
    introFade() {
        TweenLite.to(this.uAlpha, 1.0, {
            value: 1.0,
            delay: Math.random() * 2,
            ease: Linear.easeNone,
        });
    }

    // Removes color overlay
    mouseOver() {
        if (this.uBNW === 0) return;

        TweenLite.to(this.uBNW, 1, {
            value: 0,
            ease: Power2.easeOut,
        });
    }

    // Adds color overlay
    mouseOut() {
        if (this.uBNW === 1) return;

        TweenLite.to(this.uBNW, 1, {
            value: 1,
            ease: Power2.easeOut,
        });
    }

    zoomTo(_zoom) {
        // Break if already at same zoom level
        if (_zoom === this.uZoom.value) {
            return;
        }

        TweenLite.to(this.uZoom, 1.0, {
            value: _zoom,
            delay: 0,
            ease: Power4.easeOut,
        });
    }

    swipeLeft(_texLocation, _center) {
        this.zoomTo(Tile.ZOOM_LG);

        this.gridCenter = _center;
        this.slidePos += 1;
        this.slide(_texLocation, 1);
    }

    swipeRight(_texLocation, _center) {
        this.zoomTo(Tile.ZOOM_LG);

        this.gridCenter = _center;
        this.slidePos -= 1;
        this.slide(_texLocation, 0);
    }

    exitCloseup() {
        this.zoomTo(Tile.ZOOM_SM);

        // Break if already showing right texture
        if (this.currentTex === this.person.texLocation) {
            return;
        }

        this.currentTex = this.person.texLocation;

        this.slidePos += 1;
        // Set even texture if sliding to even
        if (mod(this.slidePos, 2) === 0) {
            this.uTex0.value = this.currentTex;
        } else {
            // Set odd texture if to odd
            this.uTex1.value = this.currentTex;
        }
        TweenLite.to(this.uSlider, 1.0, {
            value: this.slidePos,
            delay: this.getDelayStyle(2),
            ease: Power2.easeInOut,
        });
    }

    setPosition(_x, _y) {
        this.x = _x !== undefined ? _x : this.x;
        this.y = _y !== undefined ? _y : this.y;

        this.plane.position.set(
            (this.x + 0.5 - this.viewport.cols / 2) * Tile.WIDTH,
            (this.y + 0.5 - this.viewport.rows / 2) * Tile.HEIGHT,
            0
        );
        this.plane.updateMatrix();
    }

    // Deallocate memory from GPU
    dispose() {
        this.plane.geometry.dispose();
        this.plane.material.dispose();
    }

    update(_t) {
        this.uTime.value = _t;
    }
}
